import {
  ArrayField,
  Datagrid,
  ImageField,
  TextField,
  useResourceContext,
} from 'react-admin'
import React, { useEffect, useState } from 'react'
import { parseResource } from '~/modules/parse-resource'
import { EntityName } from '~/constants'

export const ModerationTable = () => {
  const resource = useResourceContext()

  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  useEffect(() => {
    const loadTemplate = async () => {
      const { entityName: name, template } = await parseResource(resource)

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  if (!template || !entityName) {
    return null
  }

  const child = template.relations.find(
    el => el.typeId === 2 && el.template.symbolCode !== 'review'
  ).template.symbolCode

  return <CustomField resource={child} />
}

const CustomField = ({ resource }) => {
  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  useEffect(() => {
    const loadTemplate = async () => {
      const { entityName: name, template } = await parseResource(
        '1:' + resource
      )

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  if (!template || !entityName) {
    return null
  }

  const child =
    template.relations.length !== 1 &&
    template?.relations.find(el => el.typeId === 2 || el.typeId === 1) &&
    template?.relations.find(
      el =>
        (el.typeId === 2 || el.typeId === 1) &&
        el.template.symbolCode !== 'review'
    ).template.symbolCode

  return (
    <ArrayField source={'relations'}>
      <Datagrid bulkActionButtons={false}>
        <ArrayField label={resource} source={'entities'}>
          <Datagrid bulkActionButtons={false}>
            <TextField source={'id'} />
            {template.properties.map((el, index) => {
              if (el.symbolCode !== 'availabilityLevel') {
                if (el.type && el.type.settings.type === 'file') {
                  return (
                    <ImageField
                      label={el.name}
                      source={`properties[${index}].value`}
                      src="url"
                    />
                  )
                } else if (el.type && el.type.settings)
                  return (
                    <TextField
                      label={el.name}
                      source={`properties[${index}].value`}
                    />
                  )
              }
            })}
            {template.relations.length !== 1 &&
            template.relations.find(
              el => el.typeId === 2 || el.typeId === 1
            ) ? (
                <CustomField resource={child} />
              ) : null}
          </Datagrid>
        </ArrayField>
      </Datagrid>
    </ArrayField>
  )
}
