import {
  Create,
  DatagridConfigurable,
  DateInput,
  Edit,
  EditButton,
  FilterButton,
  FilterForm,
  FunctionField,
  List,
  SelectColumnsButton,
  SimpleForm,
  TextField,
  TextInput,
  Labeled,
  NumberInput,
  Pagination,
  useNotify,
} from 'react-admin'
import moment from 'moment'
import { Stack } from '@mui/material'
import React from 'react'
import { changeField, changeFieldValues } from '~/utils/errorsHandler'

export const Applications = () => {
  return (
    <List pagination={<PostPagination />}>
      <ListToolbar />
      <DatagridConfigurable>
        <TextField source="id" label="ID" />
        <TextField source="name" label="Название" />
        <TextField source="dbName" label="Название базы данных" />
        <TextField source="code" label="Код" />
        <FunctionField
          source="createdAt"
          label="Дата создания"
          render={e => `${moment(e.createdAt).format('YYYY-MM-DD')}`}
        />
        <FunctionField
          source="updatedAt"
          label="Дата обновления"
          render={e => `${moment(e.updatedAt).format('YYYY-MM-DD')}`}
        />
        <EditButton />
      </DatagridConfigurable>
    </List>
  )
}
const CheckNameValue = (value, allValues, props) =>
  allValues.name ? undefined : 'Введите название'

const CheckCodeValue = (value, allValues, props) =>
  allValues.code ? undefined : 'Выберите код приложения'
export const ApplicationEdit = () => {
  return (
    <Edit title="Изменение информации приложения">
      <SimpleForm>
        <Labeled label="Id приложения">
          <TextInput source="id" readOnly />
        </Labeled>
        <Labeled label="Название приложения">
          <TextInput source="name" validate={CheckNameValue} />
        </Labeled>
        <Labeled label="код приложения">
          <NumberInput source="code" validate={CheckCodeValue} />
        </Labeled>
        <Labeled label="Название в БД">
          <TextInput source="dbName" />
        </Labeled>
        <Labeled label="Дата создания">
          <FunctionField
            source="createdAt"
            render={e => `${moment(e.createdAt).format('YYYY-MM-DD')}`}
          />
        </Labeled>
        <Labeled label="Дата обновления">
          <FunctionField
            source="updatedAt"
            render={e => `${moment(e.updatedAt).format('YYYY-MM-DD')}`}
          />
        </Labeled>
      </SimpleForm>
    </Edit>
  )
}

export const ApplicationCreate = () => {
  const notify = useNotify()
  const onError = error => {
    let obj = JSON.parse(error.request.responseText).message.reduce(
      (acc, { name, value }) => ({ ...acc, [name]: value })
    )
    notify(
      `${changeField(obj.field)}: ${changeFieldValues(
        obj.field,
        String(obj.value)
      )}`,
      { type: 'error' }
    )
  }
  return (
    <Create mutationOptions={{ onError }} title="Создание приложения">
      <SimpleForm>
        <Labeled label="Название приложения">
          <TextInput source="name" />
        </Labeled>
        <Labeled label="код приложения">
          <NumberInput source="code" />
        </Labeled>
        <Labeled label="Название в БД">
          <TextInput source="dbName" />
        </Labeled>
      </SimpleForm>
    </Create>
  )
}

const ListToolbar = () => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={0}
    sx={{ ml: 2, mr: 2 }}
  >
    <FilterForm filters={postFilters} />
    <div>
      <SelectColumnsButton />
      <FilterButton filters={postFilters} />
    </div>
  </Stack>
)

const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)

// Список полей фильтрации
const postFilters = [
  <TextInput label="Поиск" source="query" alwaysOn />,
  <TextInput label="Название" source="name" defaultValue="" />,
  <TextInput label="Описание" source="description" defaultValue="" />,
  <DateInput label="Дата создания" source="createdAt" />,
  <DateInput label="Дата изменения" source="updatedAt" />,
]
