import AuthApi from '~/api/auth-api'
import { AUTH_COOKIE_KEY, TOKEN_COOKIE_KEY } from '~/constants/auth'
import { assert } from '~/utils/assertion'
import moment from 'moment'
import { AuthProvider } from 'react-admin'
import Cookies from 'universal-cookie'
import { changeField, changeFieldValues } from '~/utils/errorsHandler'

const cookies = new Cookies()

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    try {
      const res = await AuthApi.login({ email: username, password })

      cookies.set(TOKEN_COOKIE_KEY, res.data.accessToken, {
        path: '/',
        expires: moment().add(1, 'day').toDate(),
      })
      cookies.set(AUTH_COOKIE_KEY, res.data.type, {
        path: '/',
        expires: moment().add(1, 'day').toDate(),
      })
      sessionStorage.setItem('applicationId', '0')
    } catch (error) {
      let obj = JSON.parse(error.request.responseText).message.reduce(
        (acc, { name, value }) => ({
          ...acc,
          [name]: value,
        })
      )
      throw new Error(
        `${changeField(obj.field)}: ${changeFieldValues(obj.field, obj.value)}`
      )
    }
  },
  checkAuth: async () => {
    assert(cookies.get(TOKEN_COOKIE_KEY), 'Не авторизован!')
    return Promise.resolve()
  },

  checkError: async error => {
    assert(error?.response?.status !== 401, 'Session timed out')
    return Promise.resolve()
  },
  logout: async (): Promise<void> => {
    cookies.remove(TOKEN_COOKIE_KEY, { path: '/' })
    cookies.remove(AUTH_COOKIE_KEY, { path: '/' })
    sessionStorage.removeItem('applicationId')
    return Promise.resolve()
  },
  getPermissions: async () => Promise.resolve(),
}

export default authProvider
