import {
  BooleanField,
  BooleanInput,
  DateInput,
  EmailField,
  FunctionField,
  ImageField,
  Labeled,
  SelectInput,
  TextField,
  TextInput,
  TimeInput
} from "react-admin"
import moment from 'moment'
import React from 'react'
import { LatLngInput } from '~/components/inputs/LatLngInput'
import { ScheduleInput } from '~/components/inputs/ScheduleInput'
import { VideoField } from '~/components/inputs/VideoField'
import { AnimationField } from '~/components/inputs/AnimationField'
import { FileInput } from '~/components/inputs/FileInput'
import { ImageInput } from '~/components/inputs/ImageInput'

export const arrayEntity = [
  { name: 'gym' },
  { name: 'trainerGym' },
  { name: 'training' },
  { name: 'stream' },
  { name: 'trainingProgram' },
  { name: 'trainingProgramDay' },
  { name: 'exercise' },
  { name: 'exerciseGroup' },
  { name: 'trainingProgramDayExercise' },
  { name: 'set' },
  { name: 'nutritionProgramDayMealDish' },
]
export const isImageField = ({ type: { settings } }) => {
  return settings.type === 'file'
}
export const isCurrencyField = ({ symbolCode }) => {
  return symbolCode === 'currency'
}
export const isSexField = ({ symbolCode }) => {
  return symbolCode === 'sex'
}
export const isPriceField = ({ symbolCode }) => {
  return symbolCode === 'price'
}
export const isStartedAt = ({ symbolCode }) => {
  return symbolCode === 'startedAt'
}
export const isAddressField = ({ symbolCode }) => {
  return symbolCode === 'address'
}
export const isDateField = ({ symbolCode }) => {
  return symbolCode === 'date'
}
export const isTimeField = ({ symbolCode }) => {
  return symbolCode === 'time'
}
export const isLongField = ({ symbolCode }) => {
  return (
    symbolCode === 'agoraToken' ||
    symbolCode === 'description' ||
    symbolCode === 'technique' ||
    symbolCode === 'recipe'
  )
}
export const isSchedulesField = ({ symbolCode }) => {
  return symbolCode === 'schedule'
}
export const isFunctionFieldFromDate = ({ type: { settings } }) => {
  return settings.type === 'schedule'
}
export const isSelectField = ({ type: { settings } }) => {
  return settings.type === 'select'
}
export const isBooleanField = ({ type: { settings } }) => {
  return settings.type === 'boolean'
}
export const isEmailField = ({ type: { settings } }) => {
  return settings.type === 'email'
}
export const isDefaultField = ({ type: { settings } }) => {
  return settings === null
}
export const getFieldForApp = (el, list = '') => {
  if (el.symbolCode === 'longitude' || el.symbolCode === 'latitude') return null

  if (isDefaultField(el)) {
    return (
      <TextField
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        source={`properties.${el.symbolCode}.value`}
      />
    )
  }

  if (isSelectField(el)) {
    return (
      <TextField
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        source={`properties.${el.symbolCode}.$selectValue`}
      />
    )
  }

  if (isBooleanField(el)) {
    return (
      <BooleanField
        readOnly={true}
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        source={`properties.${el.symbolCode}.value`}
      />
    )
  }

  if (isEmailField(el)) {
    return (
      <EmailField
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        source={`properties.${el.symbolCode}.value`}
      />
    )
  }
  if (isImageField(el)) {
    if (el.symbolCode === 'animation') {
      return <AnimationField label={el?.name} />
    }
    if (el.symbolCode === 'video') {
      return <VideoField label={el?.name} />
    }
    return (
      <ImageField
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        source={`properties.${el.symbolCode}.value`}
        src="url"
      />
    )
  }
  if (isLongField(el)) {
    return (
      <FunctionField
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        render={record => {
          if (
            !record.properties[`${el.symbolCode}`] ||
            !record.properties[`${el.symbolCode}`]?.value
          ) {
            return null
          }
          if (
            record.properties[`${el.symbolCode}`]?.value.length > 20 &&
            list !== 'show'
          ) {
            return (
              record.properties[`${el.symbolCode}`].value.slice(0, 20) + '...'
            )
          }
          return <div>{record.properties[`${el.symbolCode}`].value}</div>
        }}
      />
    )
  }
  if (isFunctionFieldFromDate(el)) {
    return (
      <FunctionField
        label="Режим работы"
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        render={record => {
          if (
            !record.properties ||
            !record.properties.schedule ||
            !record.properties.schedule.value
          )
            return null
          const arrayDate = Object.values(record.properties.schedule.value)
          return (
            <div>
              {(arrayDate || []).map(([[timeFrom, timeEnd]], index) => {
                return (
                  <div key={index} style={{ minWidth: '160px' }}>
                    {moment()
                      .day(index + 1)
                      .format('dddd')}{' '}
                    {`${timeFrom} - ${timeEnd}`}
                  </div>
                )
              })}
            </div>
          )
        }}
      />
    )
  }

  return (
    <TextField
      // emptyText={'Не заполнено'}
      key={`${el.id}-${el.name}-${el.symbolCode}`}
      label={el.name}
      source={`properties.${el.symbolCode}.value`}
    />
  )
}

export const getFieldForEditAndCreate = el => {
  if (el.symbolCode === 'longitude' || el.symbolCode === 'latitude') return null
  if (isDefaultField(el)) {
    return (
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <TextInput source={`properties.${el.symbolCode}.value`} />
      </Labeled>
    )
  }
  if (isPriceField(el)) {
    return (
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <TextInput source={`properties.${el.symbolCode}.value`} readOnly />
      </Labeled>
    )
  }
  if (isStartedAt(el)) {
    return (
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <TextInput source={`properties.${el.symbolCode}.value`} readOnly />
      </Labeled>
    )
  }
  if (isAddressField(el)) {
    return <LatLngInput el={el} />
  }
  if (isDateField(el)) {
    return (
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <DateInput source={`properties.${el.symbolCode}.value`} />
      </Labeled>
    )
  }
  if (isTimeField(el)) {
    const formatTime = inputTime => {
      return inputTime ? inputTime.split(':').slice(0, 2).join(':') : '' // Преобразование времени в формат "HH:mm"
    }

    const parseTime = (inputTime: string) => {
      return inputTime.concat(':00')
    }

    return (
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <TimeInput
          source={`properties.${el.symbolCode}.value`}
          format={formatTime}
          parse={parseTime}
        />
      </Labeled>
    )
  }
  if (isSelectField(el)) {
    return (
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <SelectInput
          source={`properties.${el.symbolCode}.value`}
          choices={el.type.valueVariants}
          optionText="value"
          optionValue="id"
        />
      </Labeled>
    )
  }
  if (isBooleanField(el)) {
    return (
      <BooleanInput
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        source={`properties.${el.symbolCode}.value`}
      />
    )
  }
  if (isImageField(el)) {
    if (el.symbolCode === 'animation' || el.symbolCode === 'video') {
      return (
        <FileInput
          label={el.name}
          source={`properties.${el.symbolCode}.value`}
          multiple={el.type.settings.multiple}
          accept={el.type.settings.mime}
        />
      )
    }

    return <ImageInput el={el} />
  }
  if (isFunctionFieldFromDate(el)) {
    return <ScheduleInput property={el} />
  }
  if(isLongField(el)) {
    return (
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <TextInput source={`properties.${el.symbolCode}.value`} multiline={true} style={{ overflow: 'auto', maxHeight: '200px', width: '320px' }}/>
      </Labeled>
    )
  }

  return (
    <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
      <TextInput source={`properties.${el.symbolCode}.value`} />
    </Labeled>
  )
}
