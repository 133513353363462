import {
  ArrayInput,
  AutocompleteInput,
  ImageField,
  Labeled,
  Loading,
  ReferenceInput,
  SimpleFormIterator,
  SimpleFormIteratorItemContext,
  TextInput,
  useDataProvider,
  useInput,
  useResourceContext,
} from 'react-admin'
import React, { useEffect, useState } from 'react'
import { EntityName } from '~/constants'
import { parseResource } from '~/modules/parse-resource'
import { EntityTemplate } from '~/@types/base-app'
import { useFormContext } from 'react-hook-form'
import { produce } from 'immer'
import { getFieldForApp, getFieldForEditAndCreate } from '~/utils/getField'

export const DishProductSelect = () => {
  const resource = useResourceContext()

  const [template, setTemplate] = useState<EntityTemplate>()
  const [templateProduct, setTemplateProduct] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  const {
    field: { value: dishIds },
  } = useInput({ source: `relations.nutritionProgramDayMealDish` })

  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const loadTemplate = async () => {
      const {
        entityName: name,
        template,
        templates,
      } = await parseResource('1:nutritionProgramDayMealDish')

      setTemplate(template)
      setTemplateProduct(
        templates.find(
          prop => prop.symbolCode === 'nutritionProgramDayMealDishProduct'
        )
      )
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  if (!template || !entityName) {
    return null
  }

  if (dishIds.length === 0) {
    return (
      <Labeled label={'Блюда'}>
        <ArrayInput label={''} source={`relations.nutritionProgramDayMealDish`}>
          <SimpleFormIterator inline></SimpleFormIterator>
        </ArrayInput>
      </Labeled>
    )
  }

  return (
    <Labeled label={'Блюда'}>
      <ArrayInput label={''} source={`relations.nutritionProgramDayMealDish`}>
        <SimpleFormIterator
          inline
          addButton={<></>}
          removeButton={<></>}
          disableClear={true}
        >
          <SimpleFormIteratorItemContext.Consumer>
            {({ index }) => (
              <DishItem
                index={index}
                template={template}
                templateProduct={templateProduct}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            )}
          </SimpleFormIteratorItemContext.Consumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Labeled>
  )
}

const DishItem = ({
  index,
  template,
  templateProduct,
  isLoading,
  setIsLoading,
}) => {
  const dataProvider = useDataProvider()
  const { setValue } = useFormContext()

  const {
    field: { value: dictionaryDishId },
  } = useInput({
    source: `relations.nutritionProgramDayMealDish[${index}].dishId`,
  })

  useEffect(() => {
    if (!dictionaryDishId) {
      return
    }
    const getDish = async () => {
      const { data } = await dataProvider.getOne(`1:dish`, {
        id: dictionaryDishId,
        meta: { original: true, getTransformed: true, fullTree: true },
      })

      const newValue = produce(data, draft => {
        draft.symbolCode = 'nutritionProgramDayMealDish'
        draft.parentId = draft.id
        delete draft.id
        draft.relations.nutritionProgramDayMealDishProduct = []
        for (const relatedEntity of draft.relations.dishProduct) {
          relatedEntity.parentId = relatedEntity.id
          delete relatedEntity.id
          relatedEntity.symbolCode = 'nutritionProgramDayMealDishProduct'
          draft.relations.nutritionProgramDayMealDishProduct.push(relatedEntity)
        }
        delete draft.relations.dishProduct
      })
      setValue(`relations.nutritionProgramDayMealDish[${index}]`, newValue, {
        shouldDirty: true,
      })
      setIsLoading(false)
    }
    setIsLoading(true)
    getDish()
  }, [dictionaryDishId])

  return (
    <>
      {
        <ReferenceInput
          source={`relations.nutritionProgramDayMealDish[${index}].dishId`}
          reference="1:dish"
        >
          <AutocompleteInput
            label="Выберите блюдо"
            source="properties"
            optionText="properties.name.value"
            filterToQuery={searchText => ({
              properties: {
                name: {
                  value: searchText,
                },
              },
            })}
          />
        </ReferenceInput>
      }
      {template.properties.map(el =>
        el.symbolCode === 'weight' || el.symbolCode === 'calories' ? (
          <TextInput
            label={`${el.name}`}
            source={`relations.nutritionProgramDayMealDish[${index}].properties.${el.symbolCode}.value`}
          />
        ) : el.symbolCode === 'images' ? (
          <ImageField
            label={el.name}
            source={`relations.nutritionProgramDayMealDish[${index}].properties.${el.symbolCode}.value`}
            src="url"
          />
        ) : (
          <TextInput
            readOnly
            label={`${el.name}`}
            source={`relations.nutritionProgramDayMealDish[${index}].properties.${el.symbolCode}.value`}
          />
        )
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <ArrayInput
          label={'Продукты'}
          source={`relations.nutritionProgramDayMealDish[${index}].relations.nutritionProgramDayMealDishProduct`}
        >
          {
            <SimpleFormIterator
              inline
              addButton={<></>}
              removeButton={<></>}
              disableClear={true}
            >
              <SimpleFormIteratorItemContext.Consumer>
                {({ index: productIndex }) => (
                  <ProductItem
                    dishIndex={index}
                    index={productIndex}
                    templateProduct={templateProduct}
                  />
                )}
              </SimpleFormIteratorItemContext.Consumer>
            </SimpleFormIterator>
          }
        </ArrayInput>
      )}
    </>
  )
}

const ProductItem = ({ dishIndex, index, templateProduct }) => {
  const dataProvider = useDataProvider()
  const { setValue } = useFormContext()

  const {
    field: { value: dictionaryProductId },
  } = useInput({
    source: `relations.nutritionProgramDayMealDish[${dishIndex}].relations.nutritionProgramDayMealDishProduct[${index}].productId`,
  })

  useEffect(() => {
    if (!dictionaryProductId) {
      return
    }
    const getDish = async () => {
      const { data } = await dataProvider.getOne(`1:product`, {
        id: dictionaryProductId,
      })
      const newValue = produce(data, draft => {
        delete draft.id
        draft.symbolCode = 'nutritionProgramDayMealDishProduct'
      })
      setValue(
        `relations.nutritionProgramDayMealDish[${dishIndex}].relations.nutritionProgramDayMealDishProduct[${index}]`,
        newValue,
        { shouldDirty: true }
      )
    }
    getDish()
  }, [dictionaryProductId])

  return (
    <>
      {templateProduct.properties.map(el =>
        el.symbolCode === 'weight' ? (
          <TextInput
            label={`${el.name}`}
            source={`relations.nutritionProgramDayMealDish[${dishIndex}].relations.nutritionProgramDayMealDishProduct[${index}].properties.${el.symbolCode}.value`}
          />
        ) : (
          <TextInput
            readOnly
            label={`${el.name}`}
            source={`relations.nutritionProgramDayMealDish[${dishIndex}].relations.nutritionProgramDayMealDishProduct[${index}].properties.${el.symbolCode}.value`}
          />
        )
      )}
    </>
  )
}
