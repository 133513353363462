import Api from '~/api/wrapper-api'
import { DataProvider } from 'react-admin'
import {
  parserParamsForGetList,
  parserParamsForGetManyReference,
} from '~/utils/dataProviderParams'
import {
  CreateUser,
  Response,
  ResponseArrayNumbers,
  ResponseArrayObjects,
  ResponseObject,
} from '~/utils/types'
import { transformImageForUser } from '~/utils/processTransformImagesAndCoordinates'

export const usersDataProvider: DataProvider = {
  async getList(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetList(params, resource)
    const res = await Api.getList(resource, { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getMany(
    resource: String,
    { ids }: { ids: ResponseArrayNumbers }
  ): Promise<ResponseArrayObjects> {
    const res = await Api.getList(resource, { filter: { id: ids } })
    return { data: res.data.rows }
  },
  async getManyReference(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetManyReference(params, resource)
    const res = await Api.getList(resource, { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getOne(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const res = await Api.get(resource, id)
    const { roles, ...rest } = res.data
    const roleId = roles.map(role => role.id)
    for (const property of rest.properties) {
      if (
        property.value &&
        property?.property?.type?.settings?.type === 'file'
      ) {
        const picture = property.value?.filter(p => p.rawFile instanceof File)

        if (!picture) {
          property.value = []
          return
        }

        property.value = property.value.map(el => ({
          ...el,
          src: el.url,
        }))
      }
    }
    return { data: { ...rest, roleId } }
  },
  async create(
    resource: String,
    {
      data: {
        applicationId,
        email,
        password,
        roles,
        regionId,
        currencyId,
        properties,
      },
    }: { data: CreateUser }
  ): Promise<ResponseObject> {
    for (const property of properties) {
      if (
        property?.symbolCode === 'avatar' ||
        property?.symbolCode === 'certificates'
      ) {
        // попадаем сюда если добавили поле но не добавили картинку
        if (property?.value === null) {
          delete property.id
          property.value = []
        }

        // попадаем сюда если мы загружаем аватарку - она приходит обьекте
        if (!Array.isArray(property?.value) && property?.value !== null) {
          property.value = [property?.value]
        }

        const picture = property?.value?.filter(p => p.rawFile instanceof File)

        // попадаем сюда если мы загрузили картинку
        if (picture) {
          property.value = await transformImageForUser(property)
          // property.id = property.property.id
        }
      }
    }

    const res = await Api.create(resource, {
      applicationId,
      email,
      password,
      roles: [roles],
      regionId,
      currencyId,
      properties,
    })
    return { data: res.data }
  },
  async update(
    resource: String,
    { data: { id, email, password, roleId, applicationId, properties } }
  ): Promise<ResponseObject> {
    for (const property of properties) {
      if (property?.property?.type?.settings?.type === 'file') {
        // попадаем сюда если мы удалили фото и в  value у нас null
        if (property?.value === null) {
          delete property.id
          property.value = []
        }

        // попадаем сюда если мы загружаем аватарку - она приходит обьекте
        if (!Array.isArray(property?.value)) {
          property.value = [property?.value]
        }

        const picture = property?.value?.filter(p => p.rawFile instanceof File)

        // попадаем сюда если мы загрузили картинку
        if (picture) {
          property.value = await transformImageForUser(property)
          property.id = property.property.id
        }
      }
    }

    const res = await Api.update(resource, id, {
      email,
      password,
      roles: roleId,
      applicationId,
      properties,
    })
    return { data: res.data }
  },
  async updateMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 updateMany:', resource, '\n', params)
    return { data: undefined }
  },
  async delete(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const res = await Api.delete(resource, id)
    return { data: res.data }
  },
  async deleteMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 deleteMany:', resource, '\n', params)
    return { data: undefined }
  },
}
