import {
  List,
  DatagridConfigurable,
  TextField,
  BulkDeleteWithConfirmButton,
  TextInput,
  SimpleForm,
  Edit,
  Create,
  Labeled,
  EditButton,
  DeleteButton,
  NumberInput,
  DateInput,
  FilterForm,
  FilterButton,
  SelectColumnsButton,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  ChipField,
  required,
  Pagination,
  useNotify,
  SelectArrayInput,
} from 'react-admin'
import { Stack } from '@mui/material'
import React from 'react'
import { AppContext, useAppContext } from '../modules/app-context'
import { changeField, changeFieldValues } from '~/utils/errorsHandler'

export const Roles = () => {
  return (
    <List pagination={<PostPagination />}>
      <ListToolbar />
      <DatagridConfigurable bulkActionButtons={<RoleButtons />} rowClick="edit">
        <TextField label="ID" source="id" />
        <TextField label="Название" source="name" />
        <TextField label="Описание" source="description" />
        <TextField label="Код роли" source="code" />
        <ReferenceField
          label="Приложения"
          source="applicationId"
          reference="applications"
        >
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
        <DeleteButton />
      </DatagridConfigurable>
    </List>
  )
}

const ListToolbar = () => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={0}
    sx={{ ml: 2, mr: 2 }}
  >
    <FilterForm filters={postFilters} />
    <div>
      <SelectColumnsButton />
      <FilterButton filters={postFilters} />
    </div>
  </Stack>
)

const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)

const CheckNameValue = (value, allValues, props) =>
  allValues.name ? undefined : 'Выберите название'
const CheckCodeValue = (value, allValues, props) =>
  allValues.code ? undefined : 'Выберите символьный код'
const CheckApplicationValue = (value, allValues, props) =>
  allValues.applicationId ? undefined : 'Выберите приложение'
// Форма редактирования сущности
export const RolesEdit = () => {
  const { applicationId } = useAppContext()
  const systemVariants = [
    {
      id: true,
      name: 'Да',
    },
    {
      id: false,
      name: 'Нет',
    },
  ]
  return (
    <Edit title="Изменение роли">
      <SimpleForm>
        <Labeled label="Id свойства">
          <TextInput source="id" readOnly />
        </Labeled>
        <Labeled label="Название роли">
          <TextInput label="" source="name" validate={CheckNameValue} />
        </Labeled>
        <Labeled label="Описание роли">
          <TextInput label="" source="description" multiline fullWidth />
        </Labeled>
        <Labeled label="Код роли">
          <NumberInput label="" source="code" validate={CheckCodeValue} />
        </Labeled>
        <Labeled label="Системная роль">
          <SelectInput
            label=""
            source="isSystem"
            choices={systemVariants}
            validate={required()}
          />
        </Labeled>
        <Labeled label="Свойства">
          <ReferenceInput source="properties" reference="users-properties">
            <SelectArrayInput
              label="Выберите свойста"
              source="properties"
              optionValue="id"
              optionText="name"
            />
          </ReferenceInput>
        </Labeled>
        {applicationId ? (
          <Labeled label="Приложение" sx={{ fontSize: 21 }}>
            <ReferenceField source="applicationId" reference="applications">
              <ChipField source="name" size="small" />
            </ReferenceField>
          </Labeled>
        ) : (
          <Labeled label="Список приложений*" sx={{ fontSize: 21 }}>
            <ReferenceInput source="applicationId" reference="applications">
              <SelectInput
                label=""
                source="applications"
                optionValue="id"
                optionText="name"
                validate={CheckApplicationValue}
              />
            </ReferenceInput>
          </Labeled>
        )}
      </SimpleForm>
    </Edit>
  )
}

// Форма создания сущности
export const RoleCreate = () => {
  const { applicationId } = useAppContext()
  const notify = useNotify()
  const onError = error => {
    let obj = JSON.parse(error.request.responseText).message.reduce(
      (acc, { name, value }) => ({ ...acc, [name]: value })
    )
    notify(
      `${changeField(obj.field)}: ${changeFieldValues(
        obj.field,
        String(obj.value)
      )}`,
      { type: 'error' }
    )
  }
  const systemVariants = [
    {
      id: true,
      name: 'Да',
    },
    {
      id: false,
      name: 'Нет',
    },
  ]
  return (
    <Create mutationOptions={{ onError }} title="Создание роли">
      <SimpleForm
        defaultValues={{
          applicationId: applicationId,
        }}
      >
        <Labeled label="Название роли">
          <TextInput label="" source="name" />
        </Labeled>
        <Labeled label="Описание роли">
          <TextInput label="" source="description" />
        </Labeled>
        <Labeled label="Код роли">
          <NumberInput label="" source="code" />
        </Labeled>
        <Labeled label="Свойства">
          <ReferenceInput source="properties" reference="users-properties">
            <SelectArrayInput
              label="Выберите свойста"
              source="properties"
              optionValue="id"
              optionText="name"
            />
          </ReferenceInput>
        </Labeled>
        <Labeled label="Системная роль">
          <SelectInput
            label=""
            source="isSystem"
            choices={systemVariants}
            validate={required()}
          />
        </Labeled>
        <Labeled label="Приложения">
          <ReferenceInput source="applicationId" reference="applications">
            <SelectInput
              label=""
              source="applications"
              optionValue="id"
              optionText="name"
              validate={CheckApplicationValue}
            />
          </ReferenceInput>
        </Labeled>
      </SimpleForm>
    </Create>
  )
}
const RoleButtons = () => (
  <>
    <BulkDeleteWithConfirmButton mutationMode="pessimistic" />
  </>
)

const postFilters = [
  <TextInput label="Поиск" source="query" alwaysOn />,
  <TextInput label="Id" source="id" defaultValue="" />,
  <TextInput label="Название" source="name" defaultValue="" />,
  <TextInput label="Описание" source="description" defaultValue="" />,
  <DateInput label="Код роли" source="code" />,
  <DateInput label="Код приложения" source="applicationId" />,
]
