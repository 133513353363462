import {
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FunctionField,
  Labeled,
  List,
  Pagination,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin'
import React, { useEffect, useState } from 'react'
import SettingsApi from '~/api/settings-api'
import moment from 'moment/moment'

export const Withdraws = () => {
  return (
    <List pagination={<WithdrawsPagination />} emptyWhileLoading>
      <Datagrid size={'medium'} rowClick="edit">
        <TextField label="ID" source="id" />
        <ReferenceField source="userId" reference="users" />
        <DateField label="Дата создания" source="createdAt" />
        <TextField label="amount" source="amount" />
        <TextField label="payoutAccount" source="payoutAccount.bankName" />
        <TextField label="payoutAccountId" source="payoutAccountId" />
        <TextField label="statusId" source="statusId" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const WithdrawsEdit = () => {
  const [dictionaryStatus, setDictionaryStatus] = useState([])

  useEffect(() => {
    SettingsApi.getDictionaryWithdrawRequest().then(res =>
      setDictionaryStatus(res.data.withdrawsStatuses)
    )
  }, [])

  if (!dictionaryStatus) {
    return null
  }

  return (
    <Edit>
      <SimpleForm>
        <Labeled label="ID">
          <TextInput label="" source="id" readOnly />
        </Labeled>
        <Labeled label="createdAt">
          <FunctionField
            source="createdAt"
            render={(e: any) => `${moment(e.createdAt).format('YYYY-MM-DD')}`}
          />
        </Labeled>
        <Labeled label="amount">
          <TextInput label="" source="amount" readOnly />
        </Labeled>
        <Labeled label="payoutAccount">
          <TextInput label="" source="payoutAccount" readOnly />
        </Labeled>
        <Payout source={'payoutAccountId'} />
        <Labeled label="Статус вывода">
          <SelectInput label="" choices={dictionaryStatus} source="statusId" />
        </Labeled>
      </SimpleForm>
    </Edit>
  )
}

const Payout = ({ source }) => {
  const record = useRecordContext()
  const [payoutAccounts, setPayoutAccounts] = useState([])
  useEffect(() => {
    SettingsApi.getPayoutAccounts(record.userId, { filter: { active: true } }).then(res =>
      setPayoutAccounts(res.data)
    )
  }, [record.userId])

  if (!payoutAccounts) {
    return null
  }
  return (
    <Labeled label="payoutAccountId">
      <SelectInput
        label=""
        choices={payoutAccounts}
        source={source}
        optionText={'bankName'}
      />
    </Labeled>
  )
}

const WithdrawsPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)
