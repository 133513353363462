import {
  List,
  DatagridConfigurable,
  Edit,
  Create,
  SimpleForm,
  TextField,
  FunctionField,
  EditButton,
  DeleteButton,
  FilterForm,
  SelectColumnsButton,
  FilterButton,
  TextInput,
  DateInput,
  Labeled,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  ChipField,
  required,
  useNotify,
} from 'react-admin'
import moment from 'moment'
import { Stack } from '@mui/material'
import React from 'react'
import { AppContext, useAppContext } from '../modules/app-context'
import { changeField, changeFieldValues } from '~/utils/errorsHandler'

export const ContentTexts = () => {
  return (
    <List>
      <ListToolbar />
      <DatagridConfigurable>
        <TextField label="ID" source="id" />
        <TextField label="Текст" source="text" />
        <FunctionField
          source="createdAt"
          label="Дата создания"
          render={e => `${moment(e.createdAt).format('YYYY-MM-DD')}`}
        />
        <FunctionField
          source="updatedAt"
          label="Дата обновления"
          render={e => `${moment(e.updatedAt).format('YYYY-MM-DD')}`}
        />
        <ReferenceField
          source="applicationId"
          reference="applications"
          label="Приложение"
        >
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
        <DeleteButton />
      </DatagridConfigurable>
    </List>
  )
}

const ListToolbar = () => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={0}
    sx={{ ml: 2, mr: 2 }}
  >
    <FilterForm filters={postFilters} />
    <div>
      <SelectColumnsButton />
      <FilterButton filters={postFilters} />
    </div>
  </Stack>
)

// Список полей фильтрации
const postFilters = [
  <TextInput label="Поиск" source="query" alwaysOn />,
  <TextInput label="Id" source="id" defaultValue="" />,
  <TextInput label="Текст" source="text" defaultValue="" />,
  <DateInput label="Дата создания" source="createdAt" />,
  <DateInput label="Дата изменения" source="updatedAt" />,
]

// Форма редактирования сущности
export const ContentTextsEdit = () => {
  const { applicationId } = useAppContext()
  return (
    <Edit title="Изменение текстового контента">
      <SimpleForm>
        <Labeled label="Id типа">
          <TextInput source="id" readOnly />
        </Labeled>
        <Labeled label="Текст">
          <TextInput source="text" />
        </Labeled>
        <Labeled label="Переводы">
          <ArrayInput label="" source="translations">
            <SimpleFormIterator inline>
              <TextInput label="" source="id" readOnly />
              <TextInput label="" source="text" />
              <ReferenceInput source="languageId" reference="languages">
                <SelectInput optionText="name" />
              </ReferenceInput>
              <TextInput label="" source="createdAt" readOnly />
              <TextInput label="" source="updatedAt" readOnly />
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
        {applicationId ? (
          <Labeled label="Приложение" sx={{ fontSize: 21 }}>
            <ReferenceField source="applicationId" reference="applications">
              <ChipField source="name" size="small" />
            </ReferenceField>
          </Labeled>
        ) : (
          <Labeled label="Список приложений*" sx={{ fontSize: 21 }}>
            <ReferenceInput source="applicationId" reference="applications">
              <SelectInput
                label=""
                source="applications"
                optionValue="id"
                optionText="name"
                validate={required()}
              />
            </ReferenceInput>
          </Labeled>
        )}
        <Labeled label="Дата создания">
          <FunctionField
            source="createdAt"
            label="Дата создания"
            render={e => `${moment(e.createdAt).format('YYYY-MM-DD')}`}
          />
        </Labeled>
        <Labeled label="Дата обновления">
          <FunctionField
            source="updatedAt"
            label="Дата обновления"
            render={e => `${moment(e.updatedAt).format('YYYY-MM-DD')}`}
          />
        </Labeled>
      </SimpleForm>
    </Edit>
  )
}

// Форма создания сущности
export const ContentTextCreate = () => {
  const { applicationId } = useAppContext()
  const notify = useNotify()
  const onError = error => {
    let obj = JSON.parse(error.request.responseText).message.reduce(
      (acc, { name, value }) => ({ ...acc, [name]: value })
    )
    notify(
      `${changeField(obj.field)}: ${changeFieldValues(
        obj.field,
        String(obj.value)
      )}`,
      { type: 'error' }
    )
  }
  return (
    <Create mutationOptions={{ onError }} title="Создание текстового контента">
      <SimpleForm
        defaultValues={{
          applicationId: applicationId,
        }}
      >
        <Labeled label="Текст">
          <TextInput source="text" />
        </Labeled>
        <Labeled label="Переводы">
          <ArrayInput label="" source="translations">
            <SimpleFormIterator inline>
              <TextInput label="" source="text" />
              <ReferenceInput source="languageId" reference="languages">
                <SelectInput optionText="name" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
        <Labeled label="Список приложений">
          <ReferenceInput source="applicationId" reference="applications">
            <SelectInput
              label=""
              optionValue="id"
              optionText="name"
              source="name"
            />
          </ReferenceInput>
        </Labeled>
      </SimpleForm>
    </Create>
  )
}
