import { convertFileToBase64 } from './image'

export const transformImageForUser = async property => {
  const picture = property?.value?.filter(p => p.rawFile instanceof File)

  const formerPictures = property?.value
    ?.filter(p => !(p.rawFile instanceof File))
    .map(item => ({
      id: item.id,
      url: item.url,
    }))

  const base64Pictures = await Promise.all(picture.map(convertFileToBase64))
  const pictures = [
    ...formerPictures,
    ...base64Pictures?.map((dataUrl, index) => {
      return {
        value: dataUrl,
        name: picture[index].rawFile.name,
        mime: picture[index].rawFile.type,
        src: dataUrl,
      }
    }),
  ]
  return pictures
}
