export const changeField = (field: string): string => {
  console.log(field)
  const fieldMap = {
    name: 'Название',
    description: 'Описание',
    settings: 'Настройки',
    applicationId: 'Приложение',
    symbolCode: 'Символьный код',
    typeId: 'Тип',
    email: 'Почта',
    password: 'Пароль',
    roles: 'Роли',
    undefined: 'Логин',
    code: 'Код',
    text: 'Текст',
  }

  return fieldMap[field] || field
}

export const changeFieldValues = (field: string, value: string): string => {
  const valueMap = {
    email: {
      'incorrect email, Должно быть строкой': 'Должно быть строкой',
      'incorrect email': 'Не корректная почта',
    },
    password: {
      'Не меньше 4 и не больше 16, Должно быть строкой':
        'Не меньше 4 и не больше 16, Должно быть строкой',
    },
    roles: {
      'roles must be an array': 'Выберите роль',
    },
    undefined: {
      'Incorrect login data': 'Не правильный логин',
    },
    name: {
      'name must be a string': 'Должно быть строкой',
    },
    description: {
      'description must be a string': 'Должно быть строкой',
      'description should not be empty, description must be a string':
        'Не должно быть пустым, Должно быть строкой',
    },
    code: {
      'code must be a number conforming to the specified constraints':
        'Должен быть числом',
    },
    symbolCode: {
      'Должно быть строкой': 'Должен быть строкой',
    },
    text: {
      'Не должно быть пустым, Должно быть строкой':
        'Не должно быть пустым, Должно быть строкой',
    },
    typeId: {
      'typeId must be a number conforming to the specified constraints':
        'Должен быть числом',
      'Должно быть числом': 'Выберите тип',
    },
  }
  if (!value.length) {
    return 'Должно быть массивом'
  }

  return valueMap[field][value] || value
}
