import { instance } from './instance'
import { ResourceRequestParams } from '~/utils/api'

/** @deprecated */
export default class Api {
  static async getList(resource: string, params: ResourceRequestParams = {}) {
    return await instance.post(`/${resource}/list`, { ...params })
  }

  static async create(resource: string, params) {
    return await instance.post(`/${resource}`, { ...params })
  }

  static async update(resource: string, id: number, params) {
    return await instance.patch(`/${resource}/${id}`, { ...params })
  }

  static async delete(resource: string, id: number) {
    return await instance.delete(`/${resource}/${id}`)
  }

  static async deleteValue(
    resource: string,
    { propertyId, entityId }: { propertyId: number; entityId: number }
  ) {
    return await instance.delete(`/${resource}`, {
      params: { propertyId, entityId },
    })
  }

  static async get(resource: string, id: number) {
    return await instance.get(`/${resource}/${id}`)
  }

  static async getValue(
    resource: string,
    { propertyId, entityId }: { propertyId: number; entityId: number }
  ) {
    return await instance.get(`/${resource}`, {
      params: { propertyId, entityId },
    })
  }

  static async getWithQuery(resource: string, id: number) {
    return await instance.get(`/${resource}?id=${id}`)
  }
}
