import {
  List,
  Pagination,
  Datagrid,
  TextField,
  Edit,
  DateField,
  FunctionField,
} from 'react-admin'
import React from 'react'
import Chat from '~/components/chat/Chat'

export const Chats = () => {
  return (
    <List pagination={<ChatsPagination />} emptyWhileLoading>
      <Datagrid rowClick={'edit'}>
        <TextField label="ChatID" source="id" />
        <TextField label="UserID" source="user.id" />
        <TextField label="Name" source="user.properties.name.value" />
        <TextField label="Surname" source="user.properties.surname.value" />
        <TextField label="Email" source="user.properties.email.value" />
        <FunctionField
          label="Роли"
          source="user.roles"
          render={record => record.user.roles.map(r => r.name).join(', ')}
        />
        <DateField label="Дата создания" source="createdAt" />
      </Datagrid>
    </List>
  )
}

export const ChatsEdit = () => {
  return (
    <Edit>
      <Chat />
    </Edit>
  )
}

const ChatsPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)
