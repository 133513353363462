import config from '~/constants/config'
import { AxiosInstance } from 'axios'
import { HandleApiErrors } from './handleApiErrors'

export interface ResourceRequestParams {
  filter?: {
    properties?: []
    relations?: []
    users?: []
  }
  pagination?: {
    offset?: number
    limit?: number
  }
  order?: {
    field: string
    by: string // 'ASC' | 'DESC'
  }
  query?: string
}

export const isAuthencationError = error => error?.response?.status === 401

export const addDebugInterceptors = (instance: AxiosInstance) => {
  if (config.ENV === 'development') {
    instance.interceptors.response.use(undefined, error => {
      console.warn('Axios error:', error.message)
      const handledError = new HandleApiErrors(error)
      throw handledError.get()
    })
  }
  if (config.ENV === 'production') {
    instance.interceptors.response.use(undefined, error => {
      const handledError = new HandleApiErrors(error)
      throw handledError.get()
    })
  }
}
