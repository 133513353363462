import {
  ArrayInput,
  Datagrid,
  Filter,
  ImageField,
  Labeled,
  ListBase,
  ListToolbar,
  Loading,
  Pagination,
  ResourceContextProvider,
  SimpleFormIterator,
  SimpleFormIteratorItemContext,
  TextField,
  TextInput,
  useDataProvider,
  useInput,
  useListContext,
  useResourceContext,
  useUnselectAll,
} from 'react-admin'
import React, { useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { useFormContext } from 'react-hook-form'
import { EntityTemplate } from '~/@types/base-app'
import { parseResource } from '~/modules/parse-resource'

export const CreateExerciseGroupDialog = () => {
  const resource = useResourceContext()

  const [template, setTemplate] = useState<EntityTemplate>()
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    const loadTemplate = async () => {
      const { template } = await parseResource('1:exerciseGroup')

      setTemplate(template)
    }

    loadTemplate()
  }, [resource])

  const unselectAll = useUnselectAll('1:exerciseGroup')

  const handlerDialogClose = () => {
    setOpen(false)
    unselectAll()
  }

  useEffect(() => {
    const handleUnload = () => {
      handlerDialogClose()
    }

    window.addEventListener('unload', handleUnload)
    return () => {
      window.removeEventListener('unload', handleUnload)
    }
  }, [])

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(!open)}>
        Добавить группу мышц
      </Button>
      <ChooseComponent template={template} />
      <Dialog
        sx={{ minHeight: 700 }}
        maxWidth={'xl'}
        open={open}
        onClose={handlerDialogClose}
      >
        <ResourceContextProvider value={`1:exerciseGroup`}>
          <ListBase disableSyncWithLocation>
            <DialogComponent setOpen={setOpen} />
          </ListBase>
        </ResourceContextProvider>
      </Dialog>
    </>
  )
}

interface IDialogComponent {
  setOpen: (open: boolean) => void
}

const DialogComponent = ({ setOpen }: IDialogComponent) => {
  const { selectedIds } = useListContext()

  const dataProvider = useDataProvider()
  const { setValue } = useFormContext()
  const unselectAll = useUnselectAll('1:exerciseGroup')

  const {
    field: { value },
  } = useInput({ source: `relations.exerciseGroup` })

  const closeFunc = () => {
    const getProduct = async () => {
      const { data } = await dataProvider.getMany(`1:exerciseGroup`, {
        ids: selectedIds,
      })

      setValue('relations.exerciseGroup', [...value, ...data], {
        shouldDirty: true,
      })
    }
    getProduct()

    setOpen(false)
    unselectAll()
  }

  return (
    <>
      <div style={{ margin: '0 0 35px 0' }}>
        <ListToolbar filters={<CustomFilter />} />
      </div>
      <Datagrid rowClick={null}>
        <TextField label="ID" source="id" />
        <TextField label="Название" source="properties.name.value" />
        <ImageField
          label={'Картинка'}
          source={`properties.image.value`}
          src="url"
        />
      </Datagrid>
      <div style={{ margin: ' 35px 0 0 0' }}>
        <Pagination />
      </div>
      <Button variant="outlined" onClick={closeFunc}>
        Выбрать
      </Button>
    </>
  )
}

const ChooseComponent = ({ template }) => {
  const {
    field: { value: productIds },
  } = useInput({ source: `relations.exerciseGroup` })

  if (productIds.length === 0) return <></>

  return (
    <Labeled label={'Группы мышц'}>
      <ArrayInput label={''} source={`relations.exerciseGroup`}>
        <SimpleFormIterator inline addButton={<></>} disableClear={true}>
          <SimpleFormIteratorItemContext.Consumer>
            {({ index }) => <ExerciseItem index={index} template={template} />}
          </SimpleFormIteratorItemContext.Consumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Labeled>
  )
}

const ExerciseItem = ({ index, template }) => {
  return (
    <>
      {!template ? (
        <Loading />
      ) : (
        <>
          <TextInput
            readOnly
            label={'Название'}
            source={`relations.exerciseGroup[${index}].properties.name.value`}
          />
          <ImageField
            label={'Картинка'}
            source={`relations.exerciseGroup[${index}].properties.image.value`}
            src="url"
          />
        </>
      )}
    </>
  )
}

const CustomFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        source="properties.name.value"
        label={'Введите название группы мышц'}
      />
    </Filter>
  )
}
