import { Labeled, required, TextInput } from 'react-admin'
import { fromAddress, setKey } from 'react-geocode'
import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'

setKey(process.env.REACT_APP_GOOGLE_MAP_KEY)

export const LatLngInput = ({ el }: any) => {
  const { setValue } = useFormContext()
  const [error, setError] = useState<boolean>(false)
  const errorMessage = 'Ошибка при преобразовании адреса в координаты'

  const getCoordinates = async (value: string = '') => {
    if (value) {
      try {
        const response = await fromAddress(value)
        setError(false)
        const { lat, lng } = response.results[0].geometry.location
        setValue('properties.latitude', { value: lat }, { shouldDirty: true })
        setValue('properties.longitude', { value: lng }, { shouldDirty: true })
      } catch (err) {
        setError(true)
        // console.error(errorMessage, err)
        setValue('properties.latitude', { value: null }, { shouldDirty: true })
        setValue('properties.longitude', { value: null }, { shouldDirty: true })
      }
    } else {
      setError(true)
      setValue('properties.latitude', { value: null }, { shouldDirty: true })
      setValue('properties.longitude', { value: null }, { shouldDirty: true })
    }
  }

  const handleInput = useCallback(
    _.throttle(event => {
      getCoordinates(event.target.value)
    }, 2000),
    []
  )

  return (
    <>
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <TextInput
          source={`properties.${el.symbolCode}.value`}
          onChange={handleInput}
          validate={[required()]}
          helperText={error ? errorMessage : false}
        />
      </Labeled>
      <Labeled label={'Широта'}>
        <TextInput source={`properties.latitude.value`} readOnly />
      </Labeled>
      <Labeled label={'Долгота'}>
        <TextInput source={`properties.longitude.value`} readOnly />
      </Labeled>
    </>
  )
}

//Определяй свойства широты и долготы примерно так имясвойства.includes('longitude')

// Кастомный инпут и филд должены принимать в качестве параметров названия свойств широты и долготы из шаблона. Внутри должны использовать контекст для получения и изменения данных.
//   Филд должен просто показывать широту/долготу
// Инпут должен иметь 3 поля, 1ый - поиск по адресу и подстановка координат в поля 2 и 3
// В результате, в датапровайдер должны отправляться сразу готовые координаты
