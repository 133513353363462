import Api from '~/api/wrapper-api'
import { DataProvider } from 'react-admin'
import { parserParamsForGetList } from '~/utils/dataProviderParams'
import {
  CreateLanguage,
  Number,
  Response,
  ResponseArrayNumbers,
  ResponseObject,
  String,
} from '~/utils/types'

export const languagesDataProvider: DataProvider = {
  async getList(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetList(params, resource)
    const res = await Api.getList(resource, { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getMany(
    resource: String,
    { ids }: { ids: ResponseArrayNumbers }
  ): Promise<{ data: [] }> {
    const res = await Api.getList(resource, { filter: { id: ids } })
    return { data: res.data.rows }
  },
  async getManyReference(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 getManyReference:', resource, '\n', params)
    return { data: undefined }
  },
  async getOne(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 getOne:', resource, '\n', params)
    return { data: undefined }
  },
  async create(
    resource: String,
    { data: { name, symbolCode, applicationId } }: { data: CreateLanguage }
  ): Promise<ResponseObject> {
    const res = await Api.create(resource, { name, symbolCode, applicationId })
    return { data: res.data }
  },
  async update(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 update:', resource, '\n', params)
    return { data: undefined }
  },
  async updateMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 updateMany:', resource, '\n', params)
    return { data: undefined }
  },
  async delete(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const res = await Api.delete(resource, id)
    return { data: res.data.rows }
  },
  async deleteMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 deleteMany:', resource, '\n', params)
    return { data: undefined }
  },
}
