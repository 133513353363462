import { EntityName } from '~/constants'
import { instance } from './instance'
import { ResourceRequestParams } from '~/utils/api'
import { RelationInGetType } from '~/utils/types'

export default class EntityApi {
  static async getTemplates(applicationId: number) {
    return await instance.get<EntityTemplate[]>(
      `/entities-templates/list/detailed/${applicationId}`
    )
  }

  static async getList(
    applicationId: number,
    entityName: EntityName,
    data: ResourceRequestParams = {}
  ) {
    return await instance.post(
      `/entities/${applicationId}/${entityName}/list`,
      data
    )
  }

  static async getOne(
    applicationId: number,
    entityName: EntityName,
    id: number,
    pull: {
      users: boolean
      properties: boolean
      children: boolean
      parent: boolean
      relations: RelationInGetType[]
    }
  ) {
    const queryParams = Object.entries(pull).reduce((accum, [key, value]) => {
      if (typeof value !== 'undefined') {
        accum.push(`${key}=${encodeURIComponent(JSON.stringify(value))}`)
      }
      return accum
    }, [])
    const queryParamsString = queryParams.length
      ? '?' + queryParams.join('&')
      : ''
    return await instance.get(
      `/entities/${applicationId}/${entityName}/${id}${queryParamsString}`
    )
  }

  static async create(
    applicationId: number,
    entityName: EntityName,
    params: Object
  ) {
    return await instance.post(`/entities/${applicationId}/${entityName}`, {
      ...params,
    })
  }

  static async update(
    applicationId: number,
    entityName: EntityName,
    id: number,
    params: RawEntity
  ) {
    return await instance.patch(
      `/entities/${applicationId}/${entityName}/${id}`,
      { ...params }
    )
  }

  static async delete(
    applicationId: number,
    entityName: EntityName,
    id: number
  ) {
    return await instance.delete(
      `/entities/${applicationId}/${entityName}/${id}`
    )
  }
}
