import { Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useListContext, useRecordContext } from 'react-admin'
import Button from '@mui/material/Button'

export const VideoField = ({ el, props }) => {
  const record = useRecordContext(props)
  const listContext = useListContext()

  const [open, setOpen] = useState<boolean>(!listContext.data)

  if (!record?.properties?.video?.value && !el) return null

  const array = el ? [el] : record?.properties?.video?.value || []

  const openVideo = (event: React.MouseEvent) => {
    setOpen(true)
    event.stopPropagation()
  }

  if (!open) {
    return (
      <Button variant="outlined" onClick={openVideo}>
        Загрузить видео
      </Button>
    )
  }

  return (
    <>
      <Stack flexDirection={'row'}>
        {[...array].map(v => {
          return (
            <video
              src={v?.url || v?.src}
              controls
              width="320"
              height="240"
              style={{ marginLeft: 10 }}
            ></video>
          )
        })}
      </Stack>
    </>
  )
}
