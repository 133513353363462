import { ImageField, ImageInput as RAImageInput } from 'react-admin'
import { convertFileToBase64 } from '~/utils/image'
import { useFormContext, useController } from 'react-hook-form'
import { useEffect } from 'react'
import _ from 'lodash'

export const ImageInput = ({ el }) => {
  const { setValue } = useFormContext()
  const controller = useController({
    name: `properties.${el.symbolCode}.value`,
  })

  useEffect(() => {
    if (!controller.field.value) {
      return
    }
    const process = async () => {
      const draft = _.isArray(controller.field.value)
        ? controller.field.value
        : [controller.field.value]
      const newValue = await Promise.all(
        draft.map(async image => {
          if (image && image.rawFile) {
            const base64 = await convertFileToBase64(image)
            return {
              name: image.rawFile.name,
              mime: 'image/jpg' || 'image/jpeg' || 'image/png',
              value: base64,
              // src: 'data:image/png;base64,' + base64,
              src: image.src,
              // url: image.src
            }
          } else return image
        })
      )
      const value = el.type.settings.multiple
        ? newValue
        : newValue.length === 0
          ? []
          : [newValue[0]]
      setValue(`properties.${el.symbolCode}`, { value }, { shouldDirty: true })
    }
    process()
  }, [controller.field.value])

  return (
    <RAImageInput
      label={el.name}
      source={`properties.${el.symbolCode}.value`}
      multiple
      accept={el.type.settings.mime.join()}
    >
      <>
        <ImageField source={'url'} title={'title'} />
        <ImageField source={'src'} title={'title'} />
      </>
    </RAImageInput>
  )
}
