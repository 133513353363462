import Api from '~/api/wrapper-api'
import { DataProvider } from 'react-admin'
import {
  parserParamsForGetList,
  parserParamsForGetManyReference,
} from '~/utils/dataProviderParams'
import {
  Response,
  ResponseArrayNumbers,
  ResponseArrayObjects,
  Number,
  ResponseObject,
  String,
  CreateContext,
} from '~/utils/types'

export const contentDataProvider: DataProvider = {
  async getList(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetList(params, resource)
    const res = await Api.getList(resource, { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getMany(
    resource: String,
    { ids }: { ids: ResponseArrayNumbers }
  ): Promise<ResponseArrayObjects> {
    const res = await Api.getList(resource, { filter: { id: ids } })
    return { data: res.data.rows }
  },
  async getManyReference(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetManyReference(params, resource)
    const res = await Api.getList(resource, { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getOne(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const res = await Api.get(resource, id)
    return { data: res.data }
  },
  async create(
    resource: String,
    {
      data: { name, description, parentId, applicationId, properties },
    }: { data: CreateContext }
  ): Promise<ResponseObject> {
    const res = await Api.create(resource, {
      name,
      description,
      parentId: parentId || null,
      applicationId,
    })
    let values = []

    if (res?.data?.id && properties.length) {
      values = await Promise.all(
        properties.map(({ propertyId, value }) =>
          Api.create('content-entities-properties-values', {
            entityId: res.data.id,
            propertyId,
            value,
          })
        )
      )
      values = values.map(e => e.data)
    }

    return { data: { ...res.data, properties: values } }
  },
  async update(
    resource: String,
    { data: { id, name, description, parentId, applicationId, properties } }
  ): Promise<ResponseObject> {
    const res = await Api.update(resource, id, {
      name,
      description,
      parentId,
      applicationId,
    })
    let values = []

    if (properties.length) {
      values = await Promise.all(
        properties.map(({ propertyId, value }) =>
          Api.create('content-entities-properties-values', {
            entityId: res.data.id,
            propertyId,
            value,
          })
        )
      )
      values = values.map(e => e.data)
    }
    return { data: { ...res.data, properties: values } }
  },
  async updateMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 UpdateMany:', resource, '\n', params)
    return { data: undefined }
  },
  async delete(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const res = await Api.delete(resource, id)
    return { data: res.data }
  },
  async deleteMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 deleteMany:', resource, '\n', params)
    return { data: undefined }
  },
}
