import {
  List,
  DatagridConfigurable,
  TextInput,
  BulkDeleteWithConfirmButton,
  Edit,
  SimpleForm,
  Create,
  TextField,
  EditButton,
  Labeled,
  FunctionField,
  DeleteButton,
  BooleanInput,
  DateInput,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  FilterForm,
  FilterButton,
  SelectColumnsButton,
  ChipField,
  Pagination,
  ArrayField,
  SingleFieldList,
  SelectArrayInput,
  useNotify,
} from 'react-admin'
import moment from 'moment/moment'
import { Stack } from '@mui/material'
import React from 'react'
import { AppContext, useAppContext } from '../modules/app-context'
import { changeField, changeFieldValues } from '~/utils/errorsHandler'

export const Properties = () => {
  return (
    <List pagination={<PostPagination />}>
      <ListToolbar />
      <DatagridConfigurable
        bulkActionButtons={<PropertyButtons />}
        rowClick="edit"
        size="medium"
      >
        <TextField label="ID" source="id" />
        <TextField label="Название" source="name" />
        <TextField label="Активность" source="active" />
        <ReferenceField
          source="applicationId"
          reference="applications"
          label="Приложение"
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="Дата создания"
          source="createdAt"
          render={e => `${moment(e.createdAt).format('YYYY-MM-DD')}`}
        />
        <FunctionField
          label="Дата обновления"
          source="updatedAt"
          render={e => `${moment(e.createdAt).format('YYYY-MM-DD')}`}
        />
        <ReferenceField
          label="Тип"
          source="typeId"
          reference="users-properties-types"
        >
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
        <DeleteButton />
      </DatagridConfigurable>
    </List>
  )
}

const ListToolbar = () => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={0}
    sx={{ ml: 2, mr: 2 }}
  >
    <FilterForm filters={postFilters} />
    <div>
      <SelectColumnsButton />
      <FilterButton filters={postFilters} />
    </div>
  </Stack>
)
const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)

const CheckNameValue = (value, allValues, props) =>
  allValues.name ? undefined : 'Введите название'

const CheckRoleValue = (value, allValues, props) =>
  allValues.roleId && allValues.roleId.length ? undefined : 'Выберите роль'

const CheckApplicationValue = (value, allValues, props) =>
  allValues.applicationId ? undefined : 'Выберите приложение'

// Форма редактирования сущности
export const PropertiesEdit = () => {
  const { applicationId } = useAppContext()
  return (
    <Edit title="Изменение свойства пользователя">
      <SimpleForm>
        <Labeled label="Id свойства">
          <TextInput source="id" readOnly />
        </Labeled>
        <Labeled label="Название свойства">
          <TextInput label="" source="name" validate={CheckNameValue} />
        </Labeled>
        <Labeled label="Символьный код">
          <TextInput source="symbolCode" readOnly />
        </Labeled>
        <Labeled label="Активность">
          <BooleanInput label="" source="active" />
        </Labeled>
        <Labeled label="Дата создания">
          <DateInput label="" source="createdAt" readOnly />
        </Labeled>
        <Labeled label="Дата обновления">
          <DateInput label="" source="updatedAt" readOnly />
        </Labeled>
        <Labeled label="Список типов">
          <ReferenceInput source="typeId" reference="users-properties-types">
            <SelectInput label="" optionValue="id" optionText="name" />
          </ReferenceInput>
        </Labeled>
        {applicationId ? (
          <Labeled label="Роли" sx={{ fontSize: 21 }}>
            <ArrayField source="roles">
              <SingleFieldList linkType={false}>
                <ChipField source="name" size="small" />
              </SingleFieldList>
            </ArrayField>
          </Labeled>
        ) : (
          <Labeled label="Список ролей" sx={{ fontSize: 21 }}>
            <ReferenceInput source="roleId" reference="roles">
              <SelectArrayInput
                label=""
                source="roles"
                optionValue="id"
                optionText="name"
                validate={CheckRoleValue}
              />
            </ReferenceInput>
          </Labeled>
        )}
        {applicationId ? (
          <Labeled label="Приложение">
            <ReferenceField source="applicationId" reference="applications">
              <ChipField source="name" size="small" />
            </ReferenceField>
          </Labeled>
        ) : (
          <Labeled label="Список приложений">
            <ReferenceInput source="applicationId" reference="applications">
              <SelectInput
                label=""
                source="applications"
                optionValue="id"
                optionText="name"
                validate={CheckApplicationValue}
              />
            </ReferenceInput>
          </Labeled>
        )}
      </SimpleForm>
    </Edit>
  )
}

// Форма создания сущности
export const PropertyCreate = () => {
  const { applicationId } = useAppContext()
  const notify = useNotify()
  const onError = error => {
    let obj = JSON.parse(error.request.responseText).message.reduce(
      (acc, { name, value }) => ({ ...acc, [name]: value })
    )
    console.log(obj)
    notify(
      `${changeField(obj.field)}: ${changeFieldValues(
        obj.field,
        String(obj.value)
      )}`,
      { type: 'error' }
    )
  }
  return (
    <Create
      mutationOptions={{ onError }}
      title="Создание свойства пользователя"
    >
      <SimpleForm
        defaultValues={{
          applicationId: applicationId,
        }}
      >
        <Labeled label="Название свойства">
          <TextInput label="" source="name" />
        </Labeled>
        <Labeled label="Активность">
          <BooleanInput label="" source="active" />
        </Labeled>
        <Labeled label="Список типов">
          <ReferenceInput source="typeId" reference="users-properties-types">
            <SelectInput
              label=""
              source="users-properties-types"
              optionValue="id"
              optionText="name"
            />
          </ReferenceInput>
        </Labeled>
        <Labeled label="Символьный код">
          <TextInput label="" source="symbolCode" />
        </Labeled>
        <Labeled label="Роли" sx={{ fontSize: 21 }}>
          <ReferenceInput source="roleId" reference="roles">
            <SelectArrayInput
              label="Выберите роль"
              source="roles"
              optionValue="id"
              optionText="name"
              validate={CheckRoleValue}
            />
          </ReferenceInput>
        </Labeled>
        <Labeled label="Список приложений">
          <ReferenceInput source="applicationId" reference="applications">
            <SelectInput
              label=""
              source="applications"
              optionValue="id"
              optionText="name"
              validate={CheckApplicationValue}
            />
          </ReferenceInput>
        </Labeled>
      </SimpleForm>
    </Create>
  )
}

const PropertyButtons = () => (
  <>
    <BulkDeleteWithConfirmButton mutationMode="pessimistic" />
  </>
)

// Список полей фильтрации
const postFilters = [
  <TextInput label="Поиск" source="query" alwaysOn />,
  <TextInput label="Название" source="name" defaultValue="" />,
  <DateInput label="Дата создания" source="createdAt" />,
  <DateInput label="Дата изменения" source="updatedAt" />,
]
