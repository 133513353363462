import Api from '~/api/wrapper-api'
import { DataProvider } from 'react-admin'
import {
  parserParamsForGetList,
  parserParamsForGetManyReference,
} from '~/utils/dataProviderParams'
import {
  CreateContextType,
  Number,
  Response,
  ResponseArrayNumbers,
  ResponseArrayObjects,
  ResponseObject,
  String,
} from '~/utils/types'

export const contentTypesDataProvider: DataProvider = {
  async getList(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetList(params, resource)
    const res = await Api.getList(resource + '/properties/types', { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getMany(
    resource: String,
    { ids }: { ids: ResponseArrayNumbers }
  ): Promise<ResponseArrayObjects> {
    const res = await Api.getList(resource, { filter: { id: ids } })
    return { data: res.data.rows }
  },
  async getManyReference(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetManyReference(params, resource)
    const res = await Api.getList(resource, { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getOne(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const res = await Api.get(resource + '/properties/types', id)
    const settingsEntries = Object.entries(res.data.settings)
    res.data.settings = settingsEntries.map(([name, value]) => ({
      name,
      value,
    }))
    return { data: res.data }
  },
  async create(
    resource: String,
    {
      data: { name, settings, symbolCode, applicationId },
    }: { data: CreateContextType }
  ): Promise<ResponseObject> {
    const settingsMap = settings.reduce(
      (map, { name, value }) => ({ ...map, [name]: value }),
      {}
    )
    const res = await Api.create(resource, {
      name,
      settings: settingsMap,
      symbolCode,
      applicationId,
    })
    return { data: res.data }
  },
  async update(resource: String, params): Promise<ResponseObject> {
    const { id, name, settings, symbolCode, applicationId } = params.data
    const settingsMap = settings.reduce(
      (acc, { name, value }) => ({ ...acc, [name]: value }),
      {}
    )
    const res = await Api.update(resource, id, {
      name,
      settings: settingsMap,
      symbolCode,
      applicationId,
    })
    return { data: res.data }
  },
  async updateMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 UpdateMany:', resource, '\n', params)
    return { data: undefined }
  },
  async delete(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const res = await Api.delete(resource + '/properties/types', id)
    return { data: res.data }
  },
  async deleteMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 deleteMany:', resource, '\n', params)
    return { data: undefined }
  },
}
