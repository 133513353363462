import Api from '~/api/wrapper-api'
import { DataProvider } from 'react-admin'
import {
  parserParamsForGetList,
  parserParamsForGetManyReference,
} from '~/utils/dataProviderParams'
import {
  Number,
  Response,
  ResponseArrayNumbers,
  ResponseArrayObjects,
  ResponseObject,
  String,
  CreateEntitiesPropertiesTypes,
} from '~/utils/types'

export const entitiesPropertiesTypesDataProvider: DataProvider = {
  async getList(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetList(params, resource)
    const res = await Api.getList(resource, { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getMany(
    resource: String,
    { ids }: { ids: ResponseArrayNumbers }
  ): Promise<ResponseArrayObjects> {
    const res = await Api.getList(resource, { filter: { id: ids } })
    return { data: res.data.rows }
  },
  async getManyReference(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetManyReference(params, resource)
    const res = await Api.getList(resource, { filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getOne(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const res = await Api.get(resource, id)
    const { settings, valueVariants } = res.data

    const settingsEntries = settings ? Object.entries(settings) : []
    const settingsData = settingsEntries.map(([name, value]) => ({
      name,
      value,
    }))
    const valueVariantsData = valueVariants
      ? valueVariants.map(name => ({ name }))
      : []
    return {
      data: {
        ...res.data,
        settings: settingsData,
        valueVariants: valueVariantsData,
      },
    }
  },
  async create(
    resource: String,
    { data }: { data: CreateEntitiesPropertiesTypes }
  ): Promise<ResponseObject> {
    const {
      name,
      description,
      settings,
      symbolCode,
      valueVariants,
      applicationId,
    } = data

    const settingsMap = settings.reduce(
      (map, { name, value }) => ({ ...map, [name]: value }),
      {}
    )
    const valueVariantsArray =
      valueVariants.length > 0 ? valueVariants.map(({ value }) => value) : ''
    const res = await Api.create(resource, {
      name,
      description,
      valueVariants: valueVariantsArray,
      settings: settingsMap,
      applicationId,
      symbolCode,
    })
    return { data: res.data }
  },
  async update(resource: String, params): Promise<ResponseObject> {
    const {
      id,
      name,
      description,
      settings,
      valueVariants,
      applicationId,
      symbolCode,
    } = params.data
    console.log(params.data)
    const settingsMap = settings.reduce(
      (acc, { name, value }) => ({ ...acc, [name]: value }),
      {}
    )
    const valueVariantsArray =
      valueVariants.length > 0
        ? valueVariants.map(valueVariant => valueVariant.name)
        : ''
    const res = await Api.update(resource, id, {
      name,
      description,
      valueVariants: valueVariantsArray,
      settings: settingsMap,
      applicationId,
      symbolCode,
    })
    return { data: res.data }
  },
  async updateMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 UpdateMany:', resource, '\n', params)
    return { data: undefined }
  },
  async delete(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const res = await Api.delete(resource, id)
    return { data: res.data.rows }
  },
  async deleteMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 deleteMany:', resource, '\n', params)
    return { data: undefined }
  },
}
