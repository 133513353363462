export class HandleApiErrors {
  private _error

  constructor(error: Object) {
    this._error = error
  }

  public get() {
    const { data } = this._error.response
    return {
      ...data,
      message: data.message.map(({ value }) => value).join(', '),
    }
  }
}
