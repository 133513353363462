import { combineDataProviders } from 'react-admin'
import { usersDataProvider } from './resource-data-providers/users-data-provider'
import { typesDataProvider } from './resource-data-providers/types-data-provider'
import { propertiesDataProvider } from './resource-data-providers/properties-data-provider'
import { rolesDataProvider } from './resource-data-providers/roles-data-provider'
import { languagesDataProvider } from './resource-data-providers/languages-data-provider'
import { contentTextsDataProvider } from './resource-data-providers/content-texts-data-provider'
import { contentDataProvider } from './resource-data-providers/content-data-provider'
import { contentTypesDataProvider } from './resource-data-providers/content-types-data-provider'
import { contentPropertiesDataProvider } from './resource-data-providers/content-properties-data-provider'
import { applicationsDataProvider } from './resource-data-providers/applications-data-provider'
import { entitiesPropertiesTypesDataProvider } from './resource-data-providers/entities-properties-types-data-provider'
import { entitiesPropertiesDataProvider } from './resource-data-providers/entities-properties-data-provider'
import { entitiesTemplatesDataProvider } from './resource-data-providers/entities-templates-data-provider'
import { contentEntitiesPropertiesValuesProvider } from './resource-data-providers/content-entities-properties-values'
import { fileStorageDataProvider } from './resource-data-providers/file-storage-data-provider'
import { entityDataProvider } from '~/providers/resource-data-providers/entity-data-provider'
import { chatProvider } from '~/providers/resource-data-providers/chat-provider'
import { WithdrawsDataProvider } from '~/providers/resource-data-providers/withdraws-data-provider'

const dataProvider = combineDataProviders(resource => {
  switch (resource) {
    case 'users-properties':
      return propertiesDataProvider
    case 'users':
      return usersDataProvider
    case 'users-properties-types':
      return typesDataProvider
    case 'roles':
      return rolesDataProvider
    case 'languages':
      return languagesDataProvider
    case 'content-texts':
      return contentTextsDataProvider
    case 'content-entities':
      return contentDataProvider
    case 'content-entities-properties-types':
      return contentTypesDataProvider
    case 'content-entities-properties':
      return contentPropertiesDataProvider
    case 'content-entities-properties-values':
      return contentEntitiesPropertiesValuesProvider
    case 'applications':
      return applicationsDataProvider
    case 'entities-properties-types':
      return entitiesPropertiesTypesDataProvider
    case 'entities-properties':
      return entitiesPropertiesDataProvider
    case 'entities-templates':
      return entitiesTemplatesDataProvider
    case 'file-storage':
      return fileStorageDataProvider
    case 'chats':
      return chatProvider
    case 'withdraws':
      return WithdrawsDataProvider
    default:
      return entityDataProvider
  }
})

export default dataProvider
