import { useRecordContext } from 'react-admin'
import React from 'react'
import GoogleMapReact from 'google-map-react'

export default function GoogleMap() {
  const record = useRecordContext()

  const lat = record.properties?.latitude?.value
    ? +record.properties.latitude.value
    : null
  const lng = record.properties?.longitude?.value
    ? +record.properties.longitude.value
    : null
  const name = record.properties?.name?.value || 'Default Name'

  if (lat === null && lng === null) return null

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat, lng },
      map,
      title: name,
    })
    return marker
  }

  const defaultProps = {
    center: {
      lat,
      lng,
    },
    zoom: 11,
  }

  return (
    <div style={{ height: '300px', width: '600px' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      />
    </div>
  )
}
