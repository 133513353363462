import {
  ArrayInput,
  BulkDeleteWithConfirmButton,
  ChipField,
  Create,
  DatagridConfigurable,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  FilterButton,
  FilterForm,
  FunctionField,
  Labeled,
  List,
  Pagination,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SelectColumnsButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  TopToolbar,
  useNotify,
  useRedirect,
} from 'react-admin'
import React from 'react'
import moment from 'moment/moment'
import { Stack } from '@mui/material'
import { useAppContext } from '../modules/app-context'
import { useFormContext } from 'react-hook-form'

export const EntitiesPropertiesTypes = () => {
  return (
    <List pagination={<PostPagination />}>
      <ListToolbar />
      <DatagridConfigurable
        bulkActionButtons={<PropetyTypeButtons />}
        rowClick="edit"
        size="medium"
      >
        <TextField label="ID" source="id" />
        <TextField label="Название" source="name" />
        <TextField label="Символьный код" source="description" />
        <TextField label="Описание" source="symbolCode" />
        <ReferenceField
          source="applicationId"
          reference="applications"
          label="Приложение"
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          source="settings"
          label="Настройки"
          render={e =>
            e.settings
              ? Object.entries(e.settings)
                .map(([key, value]) => `${key}: ${value}`)
                .join('; ')
              : null
          }
        />
        <TextField label="Значения по умолчанию" source="valueVariants" />
        <FunctionField
          source="createdAt"
          label="Дата создания"
          render={e => `${moment(e.createdAt).format('YYYY-MM-DD')}`}
        />
        <FunctionField
          source="updatedAt"
          label="Дата обновления"
          render={e => `${moment(e.updatedAt).format('YYYY-MM-DD')}`}
        />
        <EditButton />
        <DeleteButton />
      </DatagridConfigurable>
    </List>
  )
}

export const EntitiesPropertiesTypesEdit = () => {
  const { applicationId } = useAppContext()
  return (
    <Edit title="Изменение типа пользовательского свойства">
      <SimpleForm>
        <Labeled label="Id типа" sx={{ fontSize: 21 }}>
          <TextInput source="id" readOnly />
        </Labeled>
        <Labeled label="Название типа" sx={{ fontSize: 21 }}>
          <TextInput label="" source="name" />
        </Labeled>
        <Labeled label="Описание" sx={{ fontSize: 21 }}>
          <TextInput
            label=""
            source="description"
            multiline
            fullWidth
            helperText="Введите описание типа"
          />
        </Labeled>
        <Labeled label="Символьный код" sx={{ fontSize: 21 }}>
          <TextInput label="" source="symbolCode" multiline fullWidth />
        </Labeled>
        {applicationId ? (
          <Labeled label="Приложение" sx={{ fontSize: 21 }}>
            <ReferenceField source="applicationId" reference="applications">
              <ChipField source="name" size="small" />
            </ReferenceField>
          </Labeled>
        ) : (
          <Labeled label="Список приложений*" sx={{ fontSize: 21 }}>
            <ReferenceInput source="applicationId" reference="applications">
              <SelectInput
                label=""
                source="applications"
                optionValue="id"
                optionText="name"
                validate={required()}
              />
            </ReferenceInput>
          </Labeled>
        )}
        <Labeled label="Настройки" sx={{ fontSize: 21 }}>
          <ArrayInput label="" source="settings">
            <SimpleFormIterator inline>
              <TextInput
                label="Ключ"
                source="name"
                helperText="Содержит только названия"
              />
              <TextInput
                label="Значение"
                source="value"
                helperText="Значение может быть любое"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
        <Labeled label="Варианты" sx={{ fontSize: 21 }}>
          <ArrayInput label="" source="valueVariants">
            <SimpleFormIterator inline>
              <TextInput
                label="Значение"
                source="name"
                helperText="Содержит стандартные варианты"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
        <Labeled label="Дата создания" sx={{ fontSize: 21 }}>
          <DateInput label="" source="createdAt" readOnly />
        </Labeled>
        <Labeled label="Дата обновления" sx={{ fontSize: 21 }}>
          <DateInput label="" source="updatedAt" readOnly />
        </Labeled>
      </SimpleForm>
    </Edit>
  )
}
export const EntitiesPropertiesTypesCreate = () => {
  const { applicationId } = useAppContext()
  return (
    <Create title="Создание типа пользовательского свойства">
      <SimpleForm
        toolbar={<CreateActions />}
        defaultValues={{
          applicationId: applicationId,
          valueVariants: '',
        }}
      >
        <Labeled label="Название типа" sx={{ fontSize: 21 }}>
          <TextInput label="" source="name" />
        </Labeled>
        <Labeled label="Описание" sx={{ fontSize: 21 }}>
          <TextInput label="" source="description" />
        </Labeled>
        <Labeled label="Символьный код" sx={{ fontSize: 21 }}>
          <TextInput label="" source="symbolCode" multiline fullWidth />
        </Labeled>
        <Labeled label="Список приложений" sx={{ fontSize: 21 }}>
          <ReferenceInput source="applicationId" reference="applications">
            <SelectInput
              label="Выберите приложение"
              source="applications"
              optionValue="id"
              optionText="name"
            />
          </ReferenceInput>
        </Labeled>
        <Labeled label="Настройки" sx={{ fontSize: 21 }}>
          <ArrayInput label="" source="settings">
            <SimpleFormIterator inline>
              <TextInput
                label=""
                source="name"
                helperText="Содержит только названия"
              />
              <TextInput
                label=""
                source="value"
                helperText="Значение может быть любое"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
        <Labeled label="Варианты" sx={{ fontSize: 21 }}>
          <ArrayInput label="" source="valueVariants">
            <SimpleFormIterator inline>
              <TextInput
                label=""
                source="value"
                helperText="Содержит стандартные варианты"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
      </SimpleForm>
    </Create>
  )
}

const ListToolbar = () => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={0}
    sx={{ ml: 2, mr: 2 }}
  >
    <FilterForm filters={postFilters} />
    <div>
      <SelectColumnsButton />
      <FilterButton filters={postFilters} />
    </div>
  </Stack>
)

const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)

const postFilters = [
  <TextInput label="Поиск" source="query" alwaysOn />,
  <TextInput label="Название" source="name" defaultValue="" />,
  <TextInput label="Описание" source="description" defaultValue="" />,
  <DateInput label="Дата создания" source="createdAt" />,
  <DateInput label="Дата изменения" source="updatedAt" />,
]

const PropetyTypeButtons = () => (
  <>
    <BulkDeleteWithConfirmButton mutationMode="pessimistic" />
  </>
)

export const CreateActions = () => {
  const notify = useNotify()
  const { reset } = useFormContext()
  const redirect = useRedirect()

  return (
    <TopToolbar>
      <SaveButton
        label="Save"
        mutationOptions={{
          onSuccess: () => {
            notify('Запись создана')
            reset()
            redirect('/entities-properties-types')
          },
        }}
        type="button"
        variant="text"
      />
    </TopToolbar>
  )
}
