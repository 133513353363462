import { useRecordContext } from 'react-admin'
import React, { useEffect, useMemo, useRef } from 'react'
import {
  Avatar,
  ChatContainer,
  ConversationHeader,
  Message,
  MessageInput,
  MessageList,
} from '@chatscope/chat-ui-kit-react'
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
import _ from 'lodash'
import { asyncEmit, chatSocket } from '~/modules/socket'
import { MessageCreate } from '~/api/chat-api'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { MessageType } from '~/utils/types'
import { transformListToDict } from '~/modules/entity'
import moment from 'moment'

export default function Chat() {
  const record = useRecordContext()

  const chatId = record.id
  const adminId = 1

  const user = record?.members?.find(u => u.userId !== adminId)?.user
  const transformProperties = transformListToDict(user.properties)
  const avatar = transformProperties.avatar.value?.[0]?.url
  const name = transformProperties.name.value
  const surname = transformProperties.surname.value
  const fullName = `${name} ${surname ? surname : ''}`

  const queryClient = useQueryClient()
  const listRef = useRef()

  // message request
  const messagesQueryKey = useMemo(
    () =>
      [
        'chat-messages',
        _.merge(
          {},
          { filter: { chatId } },
          {
            order: {
              field: 'createdAt',
              by: 'asc',
            },
          }
        ),
      ] as const,
    [chatId]
  )
  const { data: messages } = useQuery({
    queryKey: messagesQueryKey,
    queryFn: ({ queryKey }) => {
      return asyncEmit(chatSocket, 'chats-messages-list', queryKey[1]).then(
        r => r.rows
      )
    },
    placeholderData: [],
  })

  //socket listeners
  useEffect(() => {
    const createMessageListener = message => {
      if (chatId === message.chatId) {
        queryClient.setQueryData(messagesQueryKey, old => [...old, message])
      }
    }

    chatSocket.on('chats-messages-create', createMessageListener)

    return () => {
      chatSocket.off('chats-messages-create', createMessageListener)
    }
  }, [chatId, messagesQueryKey, queryClient])

  // send message handler
  const sendMessage = async message => {
    const data: MessageCreate = message?.mime
      ? {
        text: message,
        media: [
          {
            id: message.id,
            url: message.url,
            name: message.name,
            value: message.value,
            mime: message.mime,
          },
        ],
      }
      : {
        text: message,
        chatId,
      }

    // const res = await ChatApi.sendMessage(chatId, data)
    const res = await asyncEmit(chatSocket, 'chats-messages-create', data)
    queryClient.setQueryData(messagesQueryKey, old => [...old, res])

    listRef.current?.scrollToOffset?.({ offset: 0 })
  }

  const message = m => {
    const direction =
      m.user.id === adminId ? MessageType.Outgoing : MessageType.Incoming
    return (
      <Message
        key={m.id}
        model={{
          message: m.text,
          direction,
          position: 'single',
        }}
      />
    )
  }

  const statusInfo = (
    <>
      {user.isOnline
        ? 'online'
        : `last seen ${moment(user.lastOnlineAt).format('HH:mm')}`}
    </>
  )

  return (
    <ChatContainer style={styles}>
      <ConversationHeader>
        <Avatar name="avatar" src={avatar} />
        <ConversationHeader.Content userName={fullName} info={statusInfo} />
      </ConversationHeader>

      <MessageList ref={listRef} style={{ height: 720 }}>
        {messages?.map(message)}
      </MessageList>
      <MessageInput placeholder="Type message here" onSend={sendMessage} />
    </ChatContainer>
  )
}
