import Api from '~/api/wrapper-api'
import { DataProvider } from 'react-admin'
import {
  parserParamsForGetList,
  parserParamsForGetManyReference,
} from '~/utils/dataProviderParams'
import {
  ResponseArrayObjects,
  String,
  Number,
  ResponseObject,
  Response,
  ResponseArrayNumbers,
} from '~/utils/types'

export const contentTextsDataProvider: DataProvider = {
  async getList(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetList(params, resource)
    const res = await Api.getList(resource, { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getMany(
    resource: String,
    { ids }: { ids: ResponseArrayNumbers }
  ): Promise<ResponseArrayObjects> {
    const res = await Api.getList(resource, { filter: { id: ids } })
    return { data: res.data.rows }
  },
  async getManyReference(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetManyReference(params, resource)
    const res = await Api.getList(resource, { filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getOne(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const res = await Api.get(resource, id)
    return { data: res.data }
  },
  async create(
    resource: String,
    { data: { text, translations, applicationId } }
  ): Promise<ResponseObject> {
    const res = await Api.create(resource, {
      text,
      translations,
      applicationId,
    })
    return { data: res.data }
  },
  async update(
    resource: String,
    { data: { id, text, translations, applicationId } }
  ): Promise<ResponseObject> {
    const res = await Api.update(resource, id, {
      text,
      translations,
      applicationId,
    })
    return { data: res.data }
  },
  async updateMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 updateMany:', resource, '\n', params)
    return { data: undefined }
  },
  async delete(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const res = await Api.delete(resource, id)
    return { data: res.data }
  },
  async deleteMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 deleteMany:', resource, '\n', params)
    return { data: undefined }
  },
}
