import React from 'react'

type contextApplication = {
  applicationId: number
  setApplicationId: React.Dispatch<React.SetStateAction<number>>
}

export const AppContext = React.createContext<contextApplication | null>(null)

export const useAppContext = () => {
  const value = React.useContext(AppContext)

  if (!value) {
    throw new Error('No AppContext provider')
  }

  return value
}
