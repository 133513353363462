import {
  ChipField,
  Create,
  DatagridConfigurable,
  DeleteButton,
  Edit,
  EditButton,
  FilterButton,
  FilterForm,
  Labeled,
  List,
  ReferenceField,
  ReferenceInput,
  SelectColumnsButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
} from 'react-admin'
import { Stack } from '@mui/material'
import React from 'react'
import { AppContext, useAppContext } from '../modules/app-context'
import { changeField, changeFieldValues } from '~/utils/errorsHandler'

export const ContentProperties = () => {
  return (
    <List>
      <ListToolbar />
      <DatagridConfigurable>
        <TextField label="ID" source="id" />
        <TextField label="Название" source="name" />
        <TextField label="Символьный код" source="symbolCode" />
        <ReferenceField
          label="Тип"
          source="typeId"
          reference="content-entities-properties-types/properties/types"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="applicationId"
          reference="applications"
          label="Приложение"
        >
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
        <DeleteButton />
      </DatagridConfigurable>
    </List>
  )
}
const CheckNameValue = (value, allValues, props) =>
  allValues.name ? undefined : 'Введите название'
const CheckDescriptionValue = (value, allValues, props) =>
  allValues.description ? undefined : 'Введите описание'
const CheckTypeIdValue = (value, allValues, props) =>
  allValues.typeId ? undefined : 'Выберите тип'
const CheckApplicationValue = (value, allValues, props) =>
  allValues.applicationId ? undefined : 'Выберите приложение'
const CheckCodeValue = (value, allValues, props) =>
  allValues.symbolCode ? undefined : 'Введите символьный код'
// Форма редактирования сущности
export const ContentPropertyEdit = () => {
  const { applicationId } = useAppContext()
  return (
    <Edit title="Изменение свойства сущности контента">
      <SimpleForm>
        <Labeled label="Id">
          <TextInput label="" source="id" />
        </Labeled>
        <Labeled label="Название">
          <TextInput label="" source="name" validate={CheckNameValue} />
        </Labeled>
        <Labeled label="Описание">
          <TextInput
            label=""
            source="description"
            validate={CheckDescriptionValue}
          />
        </Labeled>
        <Labeled label="Список типов">
          <ReferenceInput
            source="typeId"
            reference="content-entities-properties-types/properties/types"
          >
            <SelectInput
              label=""
              optionValue="id"
              optionText="name"
              validate={CheckTypeIdValue}
            />
          </ReferenceInput>
        </Labeled>
        {applicationId ? (
          <Labeled label="Приложение" sx={{ fontSize: 21 }}>
            <ReferenceField source="applicationId" reference="applications">
              <ChipField source="name" size="small" />
            </ReferenceField>
          </Labeled>
        ) : (
          <Labeled label="Список приложений*" sx={{ fontSize: 21 }}>
            <ReferenceInput source="applicationId" reference="applications">
              <SelectInput
                label=""
                source="applications"
                optionValue="id"
                optionText="name"
                validate={CheckApplicationValue}
              />
            </ReferenceInput>
          </Labeled>
        )}
      </SimpleForm>
    </Edit>
  )
}

// Форма создания сущности
export const ContentPropertyCreate = () => {
  const { applicationId } = useAppContext()
  const notify = useNotify()
  const onError = error => {
    let obj = JSON.parse(error.request.responseText).message.reduce(
      (acc, { name, value }) => ({ ...acc, [name]: value })
    )
    notify(
      `${changeField(obj.field)}: ${changeFieldValues(
        obj.field,
        String(obj.value)
      )}`,
      { type: 'error' }
    )
  }
  return (
    <Create
      mutationOptions={{ onError }}
      title="Создание свойства сущности контента"
    >
      <SimpleForm
        defaultValues={{
          applicationId: applicationId,
        }}
      >
        <Labeled label="Название">
          <TextInput label="" source="name" validate={CheckNameValue} />
        </Labeled>
        <Labeled label="Описание">
          <TextInput
            label=""
            source="description"
            validate={CheckDescriptionValue}
          />
        </Labeled>
        <Labeled label="Символьный код">
          <TextInput label="" source="symbolCode" validate={CheckCodeValue} />
        </Labeled>
        <Labeled label="Список типов">
          <ReferenceInput
            source="typeId"
            reference="content-entities-properties-types/properties/types"
          >
            <SelectInput
              label=""
              optionValue="id"
              optionText="name"
              validate={CheckTypeIdValue}
            />
          </ReferenceInput>
        </Labeled>
        <Labeled label="Список приложений">
          <ReferenceInput source="applicationId" reference="applications">
            <SelectInput
              label=""
              optionValue="id"
              optionText="name"
              source="name"
              validate={CheckApplicationValue}
            />
          </ReferenceInput>
        </Labeled>
      </SimpleForm>
    </Create>
  )
}

const ListToolbar = () => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={0}
    sx={{ ml: 2, mr: 2 }}
  >
    <FilterForm filters={postFilters} />
    <div>
      <SelectColumnsButton />
      <FilterButton filters={postFilters} />
    </div>
  </Stack>
)

// Список полей фильтрации
const postFilters = [<TextInput label="Поиск" source="query" alwaysOn />]
