import { instance } from './instance'
import { ResourceRequestParams } from '~/utils/api'
import { CreateChat } from '~/utils/types'

export type MessageCreate = {
  text: string
  forwardedMessages?: [
    {
      forwardedMessageId: number
    }
  ]
  media?: [
    {
      id: number
      url: string
      name: string
      value: string
      mime: string
    }
  ]
}

export default class ChatApi {
  static async getList(data: ResourceRequestParams = {}) {
    return await instance.post('/chats/list', data)
  }

  static async getOne(id: number) {
    return await instance.get(`/chats/${id}`)
  }

  static async create(data: CreateChat) {
    return await instance.post('/chats', data)
  }

  static async sendMessage(chatId: string | number, data: MessageCreate) {
    return await instance.post(`/chats/messages/${chatId}`, data)
  }

  static async getMessages(data: ResourceRequestParams = {}) {
    return await instance.post('/chats/messages/list', data)
  }

  static async dictionary() {
    return await instance.get('/chats/dictionary')
  }
}
