export const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    console.log({ file })
    const reader = new FileReader()
    reader.onload = () => {
      const result = reader.result
      if (typeof result === 'string') {
        const base64String = result.split(',')[1]
        resolve(base64String)
      } else {
        reject(new Error('Invalid result type'))
      }
    }
    reader.onerror = reject
    reader.readAsDataURL(file.rawFile)
  })
