import Api from '~/api/wrapper-api'
import { DataProvider } from 'react-admin'
import {
  parserParamsForGetList,
  parserParamsForGetManyReference,
} from '~/utils/dataProviderParams'
import {
  CreateType,
  Number,
  Response,
  ResponseArrayNumbers,
  ResponseArrayObjects,
  ResponseObject,
  String,
} from '~/utils/types'

export const typesDataProvider: DataProvider = {
  async getList(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetList(params, resource)
    const res = await Api.getList(resource, { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getMany(
    resource: String,
    { ids }: { ids: ResponseArrayNumbers }
  ): Promise<ResponseArrayObjects> {
    const res = await Api.getList(resource, { filter: { id: ids } })
    return { data: res.data.rows }
  },
  async getManyReference(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetManyReference(params, resource)
    const res = await Api.getList(resource, { filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getOne(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const res = await Api.get(resource, id)
    const { roles, settings, valueVariants } = res.data

    const roleId = roles.map(role => role.id)

    const settingsEntries = Object.entries(settings)
    const settingsData = settingsEntries.map(([name, value]) => ({
      name,
      value,
    }))
    const valueVariantsData = valueVariants
      ? valueVariants.map(name => ({ name }))
      : []

    return {
      data: {
        ...res.data,
        roleId,
        settings: settingsData,
        valueVariants: valueVariantsData,
      },
    }
  },
  async create(
    resource: String,
    { data }: { data: CreateType }
  ): Promise<ResponseObject> {
    const {
      name,
      description,
      roleId,
      settings,
      valueVariants,
      applicationId,
    } = data
    const settingsMap = settings
      ? settings.reduce(
        (map, { name, value }) => ({ ...map, [name]: value }),
        {}
      )
      : null
    const valueVariantsArray =
      valueVariants && valueVariants.length > 0
        ? valueVariants.map(({ value }) => value)
        : null
    const res = await Api.create(resource, {
      name,
      description,
      roles: roleId,
      valueVariants: valueVariantsArray,
      settings: settingsMap,
      applicationId,
    })
    return { data: res.data }
  },
  async update(resource: String, params): Promise<ResponseObject> {
    const {
      id,
      name,
      description,
      roleId,
      settings,
      valueVariants,
      applicationId,
    } = params.data
    const settingsMap = settings.reduce(
      (acc, { name, value }) => ({ ...acc, [name]: value }),
      {}
    )
    const valueVariantsArray =
      valueVariants.length > 0
        ? valueVariants.map(valueVariant => valueVariant.name)
        : null
    const res = await Api.update(resource, id, {
      name,
      description,
      valueVariants: valueVariantsArray,
      roles: roleId,
      settings: settingsMap,
      applicationId,
    })
    return { data: res.data }
  },
  async updateMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 UpdateMany:', resource, '\n', params)
    return { data: undefined }
  },
  async delete(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const res = await Api.delete(resource, id)
    return { data: res.data.rows }
  },
  async deleteMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 deleteMany:', resource, '\n', params)
    return { data: undefined }
  },
}
