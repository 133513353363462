import {
  Create,
  DatagridConfigurable,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  FilterButton,
  FilterForm,
  Labeled,
  List,
  ReferenceInput,
  SelectColumnsButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  useNotify,
  ArrayField,
  Datagrid,
  ChipField,
  required,
  Pagination,
  SimpleFormIterator,
  ArrayInput,
} from 'react-admin'
import { Stack } from '@mui/material'
import React from 'react'
import { AppContext, useAppContext } from '../modules/app-context'
import { changeField, changeFieldValues } from '~/utils/errorsHandler'

export const Contents = () => {
  return (
    <List pagination={<PostPagination />}>
      <ListToolbar />
      <DatagridConfigurable>
        <TextField label="ID" source="id" />
        <TextField label="Название" source="name" />
        <TextField label="Описание" source="description" />
        <TextField label="ID родителя" source="parentId" />
        <ReferenceField
          source="applicationId"
          reference="applications"
          label="Приложение"
        >
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
        <DeleteButton />
      </DatagridConfigurable>
    </List>
  )
}

const CheckApplicationValue = (value, allValues, props) =>
  allValues.applicationId ? undefined : 'Выберите приложение'
const CheckNameValue = (value, allValues, props) =>
  allValues.name ? undefined : 'Введите название'
const CheckDescriptionValue = (value, allValues, props) =>
  allValues.description ? undefined : 'Введите описание'
// Форма редактирования сущности
export const ContentEdit = () => {
  const { applicationId } = useAppContext()
  return (
    <Edit title="Изменение сущности контента">
      <SimpleForm>
        <Labeled label="Id сущности контента" sx={{ fontSize: 21 }}>
          <TextInput label="" source="id" readOnly />
        </Labeled>
        <Labeled label="Название сущности контента" sx={{ fontSize: 21 }}>
          <TextInput label="" source="name" validate={CheckNameValue} />
        </Labeled>
        <Labeled label="Описание сущности контента" sx={{ fontSize: 21 }}>
          <TextInput
            label=""
            source="description"
            validate={CheckDescriptionValue}
          />
        </Labeled>
        <Labeled label="Родитель сущности контента" sx={{ fontSize: 21 }}>
          <ReferenceInput source="parentId" reference="content-entities">
            <SelectInput
              label=""
              source="content"
              optionValue="id"
              optionText="name"
            />
          </ReferenceInput>
        </Labeled>
        <Labeled label="Дочерняя сущность контента" sx={{ fontSize: 21 }}>
          <ArrayField label="" source="children">
            <Datagrid bulkActionButtons={false}>
              <TextField
                label="Ключ"
                source="id"
                helperText="Содержит только названия"
              />
              <TextField
                label="Ключ"
                source="description"
                helperText="Содержит только названия"
              />
              <TextField
                label="Значение"
                source="name"
                helperText="Значение может быть любое"
              />
              <TextField
                label="Значение"
                source="createdAt"
                helperText="Значение может быть любое"
              />
              <TextField
                label="Значение"
                source="updatedAt"
                helperText="Значение может быть любое"
              />
            </Datagrid>
          </ArrayField>
        </Labeled>
        {applicationId ? (
          <Labeled label="Приложение" sx={{ fontSize: 21 }}>
            <ReferenceField source="applicationId" reference="applications">
              <ChipField source="name" size="small" />
            </ReferenceField>
          </Labeled>
        ) : (
          <Labeled label="Список приложений*" sx={{ fontSize: 21 }}>
            <ReferenceInput source="applicationId" reference="applications">
              <SelectInput
                label=""
                source="applications"
                optionValue="id"
                optionText="name"
                validate={CheckApplicationValue}
              />
            </ReferenceInput>
          </Labeled>
        )}
        <Labeled label="Свойства">
          <ArrayInput label="" source="properties">
            <SimpleFormIterator inline>
              <ReferenceInput
                source="propertyId"
                reference="content-entities-properties"
              >
                <SelectInput
                  label=""
                  optionValue="id"
                  optionText="name"
                  validate={required()}
                />
              </ReferenceInput>
              <TextInput label="" source="value" />
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
        <Labeled label="Дата создания" sx={{ fontSize: 21 }}>
          <TextField source="createdAt" readOnly />
        </Labeled>
        <Labeled label="Дата обновления" sx={{ fontSize: 21 }}>
          <TextField source="updatedAt" readOnly />
        </Labeled>
      </SimpleForm>
    </Edit>
  )
}

// Форма создания сущности
export const ContentCreate = () => {
  const { applicationId } = useAppContext()
  const notify = useNotify()
  const onError = error => {
    let obj = JSON.parse(error.request.responseText).message.reduce(
      (acc, { name, value }) => ({ ...acc, [name]: value })
    )
    notify(
      `${changeField(obj.field)}: ${changeFieldValues(
        obj.field,
        String(obj.value)
      )}`,
      { type: 'error' }
    )
  }
  return (
    <Create mutationOptions={{ onError }} title="Создание сущности контента">
      <SimpleForm
        defaultValues={{
          applicationId: applicationId,
        }}
      >
        <Labeled label="Название сущности контента" sx={{ fontSize: 21 }}>
          <TextInput label="" source="name" validate={CheckNameValue} />
        </Labeled>
        <Labeled label="Описание сущности контента" sx={{ fontSize: 21 }}>
          <TextInput
            label=""
            source="description"
            validate={CheckDescriptionValue}
          />
        </Labeled>
        <Labeled label="Родитель сущности контента" sx={{ fontSize: 21 }}>
          <ReferenceInput source="parentId" reference="content-entities">
            <SelectInput
              label=""
              source="content"
              optionValue="id"
              optionText="name"
            />
          </ReferenceInput>
        </Labeled>
        <Labeled label="Свойства">
          <ArrayInput label="" source="properties">
            <SimpleFormIterator inline>
              <ReferenceInput
                source="propertyId"
                reference="content-entities-properties"
              >
                <SelectInput
                  label=""
                  optionValue="id"
                  optionText="name"
                  validate={required()}
                />
              </ReferenceInput>
              <TextInput label="" source="value" />
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
        <Labeled label="Список приложений" sx={{ fontSize: 21 }}>
          <ReferenceInput source="applicationId" reference="applications">
            <SelectInput
              label=""
              optionValue="id"
              optionText="name"
              source="name"
              validate={CheckApplicationValue}
            />
          </ReferenceInput>
        </Labeled>
      </SimpleForm>
    </Create>
  )
}

// export const CreateActions = () => {
//     const notify = useNotify()
//     const { reset } = useFormContext()
//     const redirect = useRedirect()
//
//     return (
//         <TopToolbar>
//             <SaveButton
//                 label = "Save"
//                 mutationOptions={{
//                     onSuccess: () => {
//                         notify('Сущность контента создана')
//                         reset()
//                         redirect('/content-entities')
//                     }
//                 }}
//                 type="button"
//                 variant="text"
//             />
//         </TopToolbar>
//     )
// }
const ListToolbar = () => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={0}
    sx={{ ml: 2, mr: 2 }}
  >
    <FilterForm filters={postFilters} />
    <div>
      <SelectColumnsButton />
      <FilterButton filters={postFilters} />
    </div>
  </Stack>
)

const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)

// Список полей фильтрации
const postFilters = [
  <TextInput label="Поиск" source="query" alwaysOn />,
  <TextInput label="Id" source="id" defaultValue="" />,
  <TextInput label="Название" source="name" defaultValue="" />,
  <TextInput label="Описание" source="description" defaultValue="" />,
  <DateInput label="Id родителя" source="parentId" />,
  <DateInput label="Приложение" source="applications" />,
]
