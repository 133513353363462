import Api from '~/api/wrapper-api'
import { DataProvider } from 'react-admin'
import { parserParamsForGetList } from '~/utils/dataProviderParams'
import { Response, Number, ResponseObject, String } from '~/utils/types'

export const WithdrawsDataProvider: DataProvider = {
  async getList(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetList(params, resource)
    const res = await Api.getList(`users-accounts/${resource}`, { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getOne(resource: String, { id }: { id: Number }) {
    const data = {
      filter: { id },
    }
    const res = (await Api.getList(`users-accounts/${resource}`, data)).data
      .rows?.[0]
    return { data: res }
  },
  async update(
    resource: String,
    { data: { id, ...rest } }
  ): Promise<ResponseObject> {
    const res = await Api.update(`users-accounts/${resource}`, id, rest)
    return { data: res.data }
  },
}
