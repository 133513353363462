import Api from '~/api/wrapper-api'
import { DataProvider } from 'react-admin'
import {
  parserParamsForGetList,
  parserParamsForGetManyReference,
} from '~/utils/dataProviderParams'
import {
  Response,
  ResponseObject,
  ResponseArrayObjects,
  ResponseArrayNumbers,
  Number,
  String,
  CreateApplication,
} from '~/utils/types'
export const applicationsDataProvider: DataProvider = {
  async getList(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetList(params, resource)
    const res = await Api.getList(resource, { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getMany(
    resource: String,
    { ids }: { ids: ResponseArrayNumbers }
  ): Promise<ResponseArrayObjects> {
    const res = await Api.getList(resource, { filter: { id: ids } })
    return { data: res.data.rows }
  },
  async getManyReference(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetManyReference(params, resource)
    console.log(filter)
    const res = await Api.getList(resource, { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getOne(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const [res] = (await Api.getWithQuery(resource, id)).data
    return { data: res }
  },
  async create(
    resource: String,
    { data: { name, dbName, code } }: { data: CreateApplication }
  ): Promise<ResponseObject> {
    const res = await Api.create(resource, { name, dbName, code })
    return { data: res.data }
  },
  async update(
    resource: String,
    { data: { id, name, dbName, code } }
  ): Promise<ResponseObject> {
    const res = await Api.update(resource, id, { name, dbName, code })
    return { data: res.data }
  },
  async updateMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 UpdateMany:', resource, '\n', params)
    return { data: undefined }
  },
  async delete(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    console.log('🚧 delete:', resource, '\n', id)
    return { data: undefined }
  },
  async deleteMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 deleteMany:', resource, '\n', params)
    return { data: undefined }
  },
}
