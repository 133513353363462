import {
  List,
  DatagridConfigurable,
  DeleteButton,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Labeled,
  BulkDeleteWithConfirmButton,
  FilterForm,
  FilterButton,
  DateInput,
  SelectColumnsButton,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Pagination,
  useNotify,
} from 'react-admin'
import { Stack } from '@mui/material'
import React from 'react'
import { AppContext, useAppContext } from '../modules/app-context'
import { changeField, changeFieldValues } from '~/utils/errorsHandler'

export const Languages = () => {
  return (
    <List pagination={<PostPagination />}>
      <ListToolbar />
      <DatagridConfigurable
        bulkActionButtons={<LanguageButtons />}
        rowClick="edit"
      >
        <TextField label="ID" source="id" />
        <TextField label="Название" source="name" />
        <ReferenceField
          source="applicationId"
          reference="applications"
          label="Приложение"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField label="Символьный код" source="symbolCode" />
        <DeleteButton />
      </DatagridConfigurable>
    </List>
  )
}

const ListToolbar = () => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={0}
    sx={{ ml: 2, mr: 2 }}
  >
    <FilterForm filters={postFilters} />
    <div>
      <SelectColumnsButton />
      <FilterButton filters={postFilters} />
    </div>
  </Stack>
)

const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)

const CheckNameValue = (value, allValues, props) =>
  allValues.name ? undefined : 'Введите название'
const CheckCodeValue = (value, allValues, props) =>
  allValues.symbolCode ? undefined : 'Введите символьный код'
const CheckApplicationValue = (value, allValues, props) =>
  allValues.applicationId ? undefined : 'Выберите приложение'
// Форма создания сущности
export const LanguageCreate = () => {
  const { applicationId } = useAppContext()
  const notify = useNotify()
  const onError = error => {
    let obj = JSON.parse(error.request.responseText).message.reduce(
      (acc, { name, value }) => ({ ...acc, [name]: value })
    )
    notify(
      `${changeField(obj.field)}: ${changeFieldValues(
        obj.field,
        String(obj.value)
      )}`,
      { type: 'error' }
    )
  }
  return (
    <Create mutationOptions={{ onError }} title="Создание языка">
      <SimpleForm
        defaultValues={{
          applicationId: applicationId,
        }}
      >
        <Labeled label="Название">
          <TextInput source="name" validate={CheckNameValue} />
        </Labeled>
        <Labeled label="Символьный код">
          <TextInput source="symbolCode" validate={CheckCodeValue} />
        </Labeled>
        <Labeled label="Список приложений*" sx={{ fontSize: 21 }}>
          <ReferenceInput source="applicationId" reference="applications">
            <SelectInput
              label=""
              source="applications"
              optionValue="id"
              optionText="name"
              validate={CheckApplicationValue}
            />
          </ReferenceInput>
        </Labeled>
      </SimpleForm>
    </Create>
  )
}

const LanguageButtons = () => (
  <>
    <BulkDeleteWithConfirmButton mutationMode="pessimistic" />
  </>
)

// Список полей фильтрации
const postFilters = [
  <TextInput label="Поиск" source="query" alwaysOn />,
  <TextInput label="Id" source="id" defaultValue="" />,
  <TextInput label="Название" source="name" defaultValue="" />,
  <DateInput label="Символьный код" source="symbolCode" />,
]
