import { Labeled, TimeInput } from 'react-admin'
import moment from 'moment'
import { produce } from 'immer'
import { useFormContext } from 'react-hook-form'

export const ScheduleInput = ({ property }) => {
  return (
    <Labeled label={property.name}>
      <>
        {[...Array(7)].map((item, index) => (
          <CustomInputTime key={index} index={index} property={property} />
        ))}
      </>
    </Labeled>
  )
}

const CustomInputTime = ({ index, property }) => {
  const { setValue, watch } = useFormContext()
  const source = `properties.${property.symbolCode}.value`
  const value = watch(source)

  const formatTime = inputTime => {
    const time = moment(inputTime, 'HH:mm:ss')
    return time.format('HH:mm')
  }

  const parseTime = (inputTime: string) => {
    const time = moment(inputTime, 'HH:mm').add('00', 'seconds')
    return time.format('HH:mm:ss')
  }

  const handleClickChange = e => {
    const scheduleValue = produce(value ?? {}, draft => {
      if (!value?.[index + 1]) {
        draft[index + 1] = [['00:00:00', '00:00:00']]
      } else {
        delete draft[index + 1]
      }
    })

    setValue(`properties.${property.symbolCode}.value`, scheduleValue, {
      shouldDirty: true,
    })
  }

  return (
    <div
      key={index}
      style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
    >
      <span>
        {moment()
          .day(index + 1)
          .format('dddd')
          .slice(0, 3)}
        :
      </span>
      {!!value?.[index + 1] && (
        <>
          <TimeInput
            label={''}
            source={`properties.${property.symbolCode}.value[${index + 1}][0][0]`}
            format={formatTime}
            parse={parseTime}
          />
          <span>-</span>
          <TimeInput
            label={''}
            source={`properties.${property.symbolCode}.value[${index + 1}][0][1]`}
            format={formatTime}
            parse={parseTime}
          />
        </>
      )}
      <input
        type={'checkbox'}
        checked={!!value?.[index + 1]}
        onChange={handleClickChange}
      />
    </div>
  )
}
