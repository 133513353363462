import * as React from 'react'
import {
  Admin,
  AppBar,
  Layout,
  Menu,
  Resource,
  TitlePortal,
  useGetList,
  useRefresh,
  defaultTheme,
} from 'react-admin'
import dataProvider from './providers/data-provider'
import authProvider from './providers/auth-provider'
import { Card, CardContent, CardHeader } from '@mui/material'
import { resourceList } from '~/modules/resource-list'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { AppContext, useAppContext } from './modules/app-context'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SocketService } from '~/modules/socket'

const storedApplicationId = Number(sessionStorage.getItem('applicationId'))

const App = () => {
  const [applicationId, setApplicationId] = React.useState(
    storedApplicationId ? JSON.parse(storedApplicationId) : null
  )
  const queryClient = new QueryClient()

  const resources = resourceList.filter(
    r =>
      r.showForAll ||
      (applicationId ? r.applicationId === applicationId : !r.applicationId)
  )

  // если по данному applicationId нет ресурсов, то показываем системные таблицы
  // чтобы не застрять на getting started странице админки
  const resourcesForShow =
    resources.length > 0
      ? resources
      : resourceList.filter(r => !r.applicationId)

  // если у ресурса нет applicationId, то это системная таблица
  // TODO: назвать все ресурсы по шабону applicationId:название_сущности, как с gym
  // TODO: посмотреть что можно сделать с базовыми таблицами

  return (
    <AppContext.Provider value={{ applicationId, setApplicationId }}>
      <QueryClientProvider client={queryClient}>
        <Admin
          layout={AppLayout}
          dataProvider={dataProvider}
          authProvider={authProvider}
          dashboard={Dashboard}
          theme={theme}
          requireAuth
        >
          {resourcesForShow.map((item, index) => (
            <Resource
              key={index}
              name={item.name}
              list={item.list}
              show={item.show}
              edit={item.edit}
              create={item.create}
              options={{ label: item.options.label }}
              icon={item.icon}
            />
          ))}
        </Admin>
        <SocketService />
      </QueryClientProvider>
    </AppContext.Provider>
  )
}

const theme = {
  ...defaultTheme,
  sidebar: {
    width: 340,
    closedWidth: 55,
  },
}

const Dashboard = () => (
  <Card sx={{ marginTop: 8 }}>
    <CardHeader title="Добро пожаловать в систему по созданию зависимостей в приложении!" />
    <CardContent>
      Интерфейс предоставляет доступ к централизованному хранилищу зависимостей!
    </CardContent>
  </Card>
)

const AppLayout = props => (
  <Layout {...props} menu={AppMenu} appBar={MyAppBar} />
)

const AppMenu = () => (
  <Menu>
    <Menu.DashboardItem />

    {resourceList.map((item, index) => (
      <Menu.ResourceItem key={index} name={item.name} />
    ))}
  </Menu>
)

const ApplicationList = () => {
  const { applicationId, setApplicationId } = useAppContext()
  const refresh = useRefresh()

  const { data } = useGetList('applications', {
    sort: { field: 'id', order: 'ASC' },
  })

  const list = [
    {
      id: null,
      name: 'Системные',
    },
    ...(data || []),
  ]

  const handleChange = (event: SelectChangeEvent) => {
    setApplicationId(event.target.value)
    sessionStorage.setItem('applicationId', event.target.value)
    refresh()
  }

  return (
    <FormControl sx={{ maxWidth: 300, width: 300 }}>
      <InputLabel id="simple-select-label">Приложение</InputLabel>
      <Select
        sx={{ height: 40 }}
        labelId="simple-select-label"
        id="simple-select"
        value={String(applicationId)}
        label="Приложение"
        onChange={handleChange}
      >
        {list.map((item, index) => (
          <MenuItem value={item.id} key={index}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const MyAppBar = () => (
  <AppBar>
    <TitlePortal />
    <ApplicationList />
  </AppBar>
)

export default App
