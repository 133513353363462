import {
  ArrayField,
  ArrayInput,
  BulkDeleteWithConfirmButton,
  ChipField,
  Create,
  DatagridConfigurable,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  FilterButton,
  FilterForm,
  FunctionField,
  Labeled,
  List,
  Pagination,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectColumnsButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SingleFieldList,
  TextField,
  TextInput,
  useNotify,
} from 'react-admin'

import { Stack } from '@mui/material'
import React from 'react'
import moment from 'moment'
import { useAppContext } from '../modules/app-context'
import { changeField } from '~/utils/errorsHandler'

export const Types = () => {
  return (
    <List pagination={<PostPagination />}>
      <ListToolbar />
      <DatagridConfigurable bulkActionButtons={<TypeButtons />} rowClick="edit">
        <TextField label="ID" source="id" />
        <TextField label="Название" source="name" />
        <TextField label="Описание" source="description" />
        <FunctionField
          source="createdAt"
          label="Дата создания"
          render={e => `${moment(e.createdAt).format('YYYY-MM-DD')}`}
        />
        <FunctionField
          source="updatedAt"
          label="Дата обновления"
          render={e => `${moment(e.updatedAt).format('YYYY-MM-DD')}`}
        />
        <FunctionField
          source="settings"
          label="Настройки"
          render={e =>
            e.settings
              ? Object.entries(e.settings)
                .map(([key, value]) => `${key}: ${value}`)
                .join('; ')
              : null
          }
        />
        <ReferenceField
          source="applicationId"
          reference="applications"
          label="Приложение"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField label="Значения по умолчанию" source="valueVariants" />
        <EditButton />
        <DeleteButton />
      </DatagridConfigurable>
    </List>
  )
}

const ListToolbar = () => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={0}
    sx={{ ml: 2, mr: 2 }}
  >
    <FilterForm filters={postFilters} />
    <div>
      <SelectColumnsButton />
      <FilterButton filters={postFilters} />
    </div>
  </Stack>
)

const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)
// Список полей фильтрации
const postFilters = [
  <TextInput label="Поиск" source="query" alwaysOn />,
  <TextInput label="Название" source="name" defaultValue="" />,
  <TextInput label="Описание" source="description" defaultValue="" />,
  <DateInput label="Дата создания" source="createdAt" />,
  <DateInput label="Дата изменения" source="updatedAt" />,
]
const CheckNameValue = (value, allValues, props) =>
  allValues.name ? undefined : 'Введите название'

const CheckRoleValue = (value, allValues, props) =>
  allValues.roleId && allValues.roleId.length > 0 ? undefined : 'Выберите роль'

const CheckApplicationValue = (value, allValues, props) =>
  allValues.applicationId ? undefined : 'Выберите приложение'
// Форма редактирования сущности
export const TypesEdit = () => {
  const { applicationId } = useAppContext()
  return (
    <Edit title="Изменение типа пользовательского свойства">
      <SimpleForm>
        <Labeled label="Id типа" sx={{ fontSize: 21 }}>
          <TextInput source="id" readOnly />
        </Labeled>
        <Labeled label="Название типа" sx={{ fontSize: 21 }}>
          <TextInput label="" source="name" validate={CheckNameValue} />
        </Labeled>
        <Labeled label="Описание" sx={{ fontSize: 21 }}>
          <TextInput label="" source="description" multiline fullWidth />
        </Labeled>
        {applicationId ? (
          <Labeled label="Приложение" sx={{ fontSize: 21 }}>
            <ReferenceField source="applicationId" reference="applications">
              <ChipField source="name" size="small" />
            </ReferenceField>
          </Labeled>
        ) : (
          <Labeled label="Список приложений*" sx={{ fontSize: 21 }}>
            <ReferenceInput source="applicationId" reference="applications">
              <SelectInput
                label=""
                source="applications"
                optionValue="id"
                optionText="name"
                validate={CheckApplicationValue}
              />
            </ReferenceInput>
          </Labeled>
        )}
        {applicationId ? (
          <Labeled label="Роли" sx={{ fontSize: 21 }}>
            <ArrayField source="roles">
              <SingleFieldList linkType={false}>
                <ChipField source="name" size="small" />
              </SingleFieldList>
            </ArrayField>
          </Labeled>
        ) : (
          <Labeled label="Список ролей" sx={{ fontSize: 21 }}>
            <ReferenceInput source="roleId" reference="roles">
              <SelectArrayInput
                label=""
                source="roles"
                optionValue="id"
                optionText="name"
                validate={CheckRoleValue}
              />
            </ReferenceInput>
          </Labeled>
        )}
        <Labeled label="Настройки" sx={{ fontSize: 21 }}>
          <ArrayInput label="" source="settings">
            <SimpleFormIterator inline>
              <TextInput
                label="Ключ"
                source="name"
                helperText="Содержит только названия"
              />
              <TextInput
                label="Значение"
                source="value"
                helperText="Значение может быть любое"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
        <Labeled label="Варианты" sx={{ fontSize: 21 }}>
          <ArrayInput label="" source="valueVariants">
            <SimpleFormIterator inline>
              <TextInput
                label="Значение"
                source="name"
                helperText="Содержит стандартные варианты"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
        <Labeled label="Дата создания" sx={{ fontSize: 21 }}>
          <DateInput label="" source="createdAt" readOnly />
        </Labeled>
        <Labeled label="Дата обновления" sx={{ fontSize: 21 }}>
          <DateInput label="" source="updatedAt" readOnly />
        </Labeled>
      </SimpleForm>
    </Edit>
  )
}

// Форма создания сущности
export const TypeCreate = () => {
  const { applicationId } = useAppContext()
  const notify = useNotify()
  const onError = error => {
    let obj = JSON.parse(error.request.responseText).message.reduce(
      (acc, { name, value }) => ({ ...acc, [name]: value })
    )
    notify(`${changeField(obj.field)}: ${obj.value}`, { type: 'error' })
  }
  return (
    <Create
      mutationOptions={{ onError }}
      title="Создание типа пользовательского свойства"
    >
      <SimpleForm
        defaultValues={{
          applicationId: applicationId,
        }}
      >
        <Labeled label="Название типа" sx={{ fontSize: 21 }}>
          <TextInput label="" source="name" validate={CheckNameValue} />
        </Labeled>
        <Labeled label="Описание" sx={{ fontSize: 21 }}>
          <TextInput label="" source="description" />
        </Labeled>
        <Labeled label="Список приложений" sx={{ fontSize: 21 }}>
          <ReferenceInput source="applicationId" reference="applications">
            <SelectInput
              label="Выберите приложение"
              source="applications"
              optionValue="id"
              optionText="name"
              validate={CheckApplicationValue}
            />
          </ReferenceInput>
        </Labeled>
        <Labeled label="Роли" sx={{ fontSize: 21 }}>
          <ReferenceInput source="roleId" reference="roles">
            <SelectArrayInput
              label="Выберите роль"
              source="roles"
              optionValue="id"
              optionText="name"
              validate={CheckRoleValue}
            />
          </ReferenceInput>
        </Labeled>
        <Labeled label="Настройки" sx={{ fontSize: 21 }}>
          <ArrayInput label="" source="settings">
            <SimpleFormIterator inline>
              <TextInput
                label=""
                source="name"
                helperText="Содержит только названия"
              />
              <TextInput
                label=""
                source="value"
                helperText="Значение может быть любое"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
        <Labeled label="Варианты" sx={{ fontSize: 21 }}>
          <ArrayInput label="" source="valueVariants">
            <SimpleFormIterator inline>
              <TextInput
                label=""
                source="value"
                helperText="Содержит стандартные варианты"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Labeled>
      </SimpleForm>
    </Create>
  )
}

const TypeButtons = () => (
  <>
    <BulkDeleteWithConfirmButton mutationMode="pessimistic" />
  </>
)
