import { instance } from './instance'

export default class SettingsApi {
  static async getRegions() {
    return await instance.get(`/public/regions/1`)
  }

  static async getCurrencies() {
    return await instance.get(`/public/currencies/1`)
  }

  static async getDictionaryWithdrawRequest() {
    return await instance.get(`/users-accounts/dictionary`)
  }

  static async getPayoutAccounts(id: number, data: any) {
    return await instance.post(`payout-accounts/${id}`, data)
  }
}
