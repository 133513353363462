import Api from '~/api/wrapper-api'
import { DataProvider } from 'react-admin'
import {
  parserParamsForGetList,
  parserParamsForGetManyReference,
} from '~/utils/dataProviderParams'
import {
  Response,
  ResponseObject,
  ResponseArrayObjects,
  String,
  Number,
  ResponseArrayNumbers,
  CreateUser,
} from '~/utils/types'

export const fileStorageDataProvider: DataProvider = {
  async getList(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 getList:', resource, '\n', params)
    return { data: undefined }
  },
  async getMany(
    resource: String,
    { ids }: { ids: ResponseArrayNumbers }
  ): Promise<ResponseArrayObjects> {
    console.log('🚧 getMany:', resource, '\n', ids)
    return { data: undefined }
  },
  async getManyReference(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 getManyReference:', resource, '\n', params)
    return { data: undefined }
  },
  async getOne(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const { data } = await Api.get(resource, id)
    console.log('getOne', data)
    return { data }
  },
  async create(resource, params) {
    console.log('🚧 create:', resource, '\n', params)
    return { data: undefined }
  },
  async update(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 update:', resource, '\n', params)
    return { data: undefined }
  },
  async updateMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 updateMany:', resource, '\n', params)
    return { data: undefined }
  },
  async delete(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    console.log('🚧 delete:', resource, '\n', id)
    return { data: undefined }
  },
  async deleteMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 deleteMany:', resource, '\n', params)
    return { data: undefined }
  },
}
