import {
  ArrayInput,
  AutocompleteInput,
  BulkDeleteWithConfirmButton,
  Datagrid,
  Filter,
  ImageField,
  Labeled,
  ListBase,
  ListToolbar,
  Loading,
  Pagination,
  ReferenceInput,
  ResourceContextProvider,
  SelectInput,
  SimpleFormIterator,
  SimpleFormIteratorItemContext,
  TextField,
  TextInput,
  useDataProvider,
  useInput,
  useListContext,
  useListController,
  useRecordContext,
  useResourceContext,
  useUnselectAll,
} from 'react-admin'
import React, { useEffect, useState } from 'react'
import { EntityTemplate } from '~/@types/base-app'
import { EntityName } from '~/constants'
import { parseResource } from '~/modules/parse-resource'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { getFieldForApp } from '~/utils/getField'
import { useFormContext } from 'react-hook-form'
import { produce } from 'immer'

export const CreateDishDialog = () => {
  const resource = useResourceContext()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  const [open, setOpen] = useState<boolean>(false)
  const [templateProduct, setTemplateProduct] = useState<EntityTemplate>()

  useEffect(() => {
    console.log('mounted')
  }, [])

  useEffect(() => {
    const loadTemplate = async () => {
      const {
        entityName: name,
        template,
        templates,
      } = await parseResource('1:product')

      setTemplateProduct(templates.find(prop => prop.symbolCode === 'dish'))
      console.log(templateProduct)

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  const unselectAll = useUnselectAll('1:product')

  const handlerDialogClose = () => {
    setOpen(false)
    unselectAll()
    console.log('qwerty')
  }

  useEffect(() => {
    const handleUnload = () => {
      handlerDialogClose()
    }

    window.addEventListener('unload', handleUnload)
    return () => {
      window.removeEventListener('unload', handleUnload)
    }
  }, [])

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(!open)}>
        Добавить продукт
      </Button>
      <ChooseComponent
        template={template}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <Dialog
        sx={{ minHeight: 700 }}
        maxWidth={'xl'}
        open={open}
        onClose={handlerDialogClose}
      >
        <ResourceContextProvider value={`1:product`}>
          <ListBase disableSyncWithLocation>
            <DialogComponent setOpen={setOpen} template={template} />
          </ListBase>
        </ResourceContextProvider>
      </Dialog>
    </>
  )
}

const DialogComponent = ({ setOpen, template }) => {
  const { selectedIds } = useListContext()
  const dataProvider = useDataProvider()
  const { setValue } = useFormContext()
  const {
    field: { value },
  } = useInput({ source: `relations.dishProduct` })

  const unselectAll = useUnselectAll('1:product')

  const closeFunc = () => {
    const getProduct = async () => {
      const { data } = await dataProvider.getMany(`1:product`, {
        ids: selectedIds,
      })

      const newValue = produce(data, draft => {
        for (const product of draft) {
          product.symbolCode = 'dishProduct'
          product.parentId = product.id
          delete product.id
        }
      })
      setValue('relations.dishProduct', [...value, ...newValue], {
        shouldDirty: true,
      })
    }
    getProduct()

    setOpen(false)
    unselectAll()
  }

  return (
    <>
      <div style={{ margin: '0 0 35px 0' }}>
        <ListToolbar filters={<CustomFilter />} />
      </div>
      <Datagrid rowClick={null}>
        <TextField label="ID" source="id" />
        {template.properties.map(el => getFieldForApp(el))}
      </Datagrid>
      <Pagination />
      <Button variant="outlined" onClick={closeFunc}>
        Выбрать
      </Button>
    </>
  )
}

const ChooseComponent = ({ template, isLoading, setIsLoading }) => {
  const {
    field: { value: productIds },
  } = useInput({ source: `relations.dishProduct` })

  if (productIds.length === 0) return <></>

  return isLoading ? (
    <Loading />
  ) : (
    <Labeled label={'Продукты'}>
      <ArrayInput label={''} source={`relations.dishProduct`}>
        <SimpleFormIterator inline addButton={<></>} disableClear={true}>
          <SimpleFormIteratorItemContext.Consumer>
            {({ index }) => <ProductItem index={index} template={template} />}
          </SimpleFormIteratorItemContext.Consumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Labeled>
  )
}

const ProductItem = ({ index, template }) => {
  return (
    <>
      {!template ? (
        <Loading />
      ) : (
        template.properties.map(el =>
          el.symbolCode === 'weight' ? (
            <TextInput
              label={`${el.name}`}
              source={`relations.dishProduct[${index}].properties.${el.symbolCode}.value`}
            />
          ) : el.symbolCode === 'images' ? (
            <ImageField
              label={el.name}
              source={`relations.dishProduct[${index}].properties.${el.symbolCode}.value`}
              src="url"
            />
          ) : (
            <TextInput
              readOnly
              label={`${el.name}`}
              source={`relations.dishProduct[${index}].properties.${el.symbolCode}.value`}
            />
          )
        )
      )}
    </>
  )
}

const CustomFilter = props => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        source="properties.name.value"
        label={'Введите название продукта'}
      />
    </Filter>
  )
}
