import Api from '~/api/wrapper-api'
import { DataProvider } from 'react-admin'
import {
  parserParamsForGetList,
  parserParamsForGetManyReference,
} from '~/utils/dataProviderParams'
import {
  CreateProperty,
  Number,
  Response,
  ResponseArrayNumbers,
  ResponseArrayObjects,
  ResponseObject,
  String,
} from '~/utils/types'

export const propertiesDataProvider: DataProvider = {
  async getList(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetList(params, resource)
    let { data } = await Api.getList(resource, { ...filter })
    if (params?.meta?.filter) {
      data.rows = data.rows.filter(row => params.meta.filter(row))
    }
    return { data: data.rows, total: data.count }
  },
  async getMany(
    resource: String,
    { ids }: { ids: ResponseArrayNumbers }
  ): Promise<ResponseArrayObjects> {
    const res = await Api.getList(resource, { filter: { id: ids } })
    return { data: res.data.rows }
  },
  async getManyReference(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetManyReference(params, resource)
    const res = await Api.getList(resource, { filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getOne(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const res = await Api.get(resource, id)
    const { roles } = res.data
    const roleId = roles.map(role => role.id)
    return { data: { ...res.data, roleId } }
  },
  async create(
    resource: String,
    {
      data: { name, active, typeId, roleId, applicationId, symbolCode },
    }: { data: CreateProperty }
  ): Promise<ResponseObject> {
    const res = await Api.create(resource, {
      name,
      active,
      roles: roleId,
      applicationId,
      symbolCode,
      typeId: typeId || null,
    })
    return { data: res.data }
  },
  async update(
    resource: String,
    { data: { id, name, active, typeId, applicationId, roleId } }
  ): Promise<ResponseObject> {
    const res = await Api.update(resource, id, {
      name,
      active,
      applicationId,
      roles: roleId,
      typeId: typeId || null,
    })
    return { data: res.data }
  },
  async updateMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 UpdateMany:', resource, '\n', params)
    return { data: undefined }
  },
  async delete(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const res = await Api.delete(resource, id)
    return { data: res.data.rows }
  },
  async deleteMany(resource: String, params): Promise<ResponseObject> {
    console.log('🚧 deleteMany:', resource, '\n', params)
    return { data: undefined }
  },
}
