import { DataProvider, withLifecycleCallbacks } from 'react-admin'
import { parserParamsForGetList } from '~/utils/dataProviderParams'
import {
  CreateChat,
  Response,
  ResponseArrayNumbers,
  ResponseArrayObjects,
  ResponseObject,
  String,
} from '~/utils/types'
import ChatApi from '~/api/chat-api'
import { removeObjectsWithNullOrUndefinedValues } from '~/utils/transformRelation'
import { transformListToDict } from '~/modules/entity'

export const baseChatsProvider: DataProvider = {
  async getList(resource: String, params): Promise<Response> {
    const filter = parserParamsForGetList(params, resource)
    const res = await ChatApi.getList({ ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getMany(
    resource: String,
    { ids }: { ids: ResponseArrayNumbers }
  ): Promise<ResponseArrayObjects> {
    const res = await ChatApi.getList({ filter: { id: ids } })
    return { data: res.data.rows }
  },
  async getOne(
    resource: String,
    { id }: { id: Number }
  ): Promise<ResponseObject> {
    const res = await ChatApi.getOne(id)
    return { data: res.data }
  },
  async create(
    resource: String,
    { data: { name, typeId, entityId, users } }: { data: CreateChat }
  ): Promise<ResponseObject> {
    const res = await ChatApi.create({ name, typeId, entityId, users })
    return { data: res.data }
  },
}

export const chatProvider: DataProvider = withLifecycleCallbacks(
  baseChatsProvider,
  [
    {
      resource: 'chats',
      afterGetList: async (params, dataProvider: DataProvider) => {
        const paramsWithoutNullValues =
          removeObjectsWithNullOrUndefinedValues(params)

        const updatedParams = paramsWithoutNullValues.data.map(d => {
          const user = d?.members?.find(u => u.userId !== 1)?.user
          if (user) {
            d.user = {
              ...user,
              properties: transformListToDict(user.properties),
            }
          }
          return d
        })

        return {
          ...paramsWithoutNullValues,
          data: updatedParams,
        }
      },
    },
  ]
)
