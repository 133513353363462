import { Labeled, ReferenceInput, SelectInput } from "react-admin"
import React, { useEffect, useState } from "react"
import { FullNameField } from "~/components/TrainerGumSelect/TrainerIdSelect"
import { useInput } from "ra-core"
import { useFormContext } from "react-hook-form"
import _ from 'lodash'

export const ClientAndGymSelect = () => {
  const { setValue } = useFormContext()

  const {
    field: { value: usersId }
  } = useInput({ source: `userIdsByTypeId[1]` })

  const [show, setShow] = useState<boolean>(false)
  const [trainerId, setTrainerId] = useState<number>(usersId[0])

  useEffect(() => {
    if(usersId){
      setShow(true)
      if(!_.isArray(usersId) && usersId !== trainerId){
        setValue('relations.trainerGym', [], {shouldDirty: true})
        setTrainerId(usersId)
      }
    } else {
      setShow(false)
    }
  }, [usersId])

  return (
    <>
      <Labeled label="Тренер">
        <ReferenceInput
          source="userIdsByTypeId[1]"
          reference="users"
          filter={{ roles: [4] }}
        >
          <SelectInput
            label="Выберите id тренера"
            source="[id]"
            optionText={<FullNameField />}
          />
        </ReferenceInput>
      </Labeled>
      {
        show &&
        <>
          <Labeled label="Клиент">
            <ReferenceInput
              source="userIdsByTypeId[2]"
              reference="users"
              filter={{ roles: [3] }}
            >
              <SelectInput
                label="Выберите id лиента"
                source="id"
                optionText={<FullNameField />}
              />
            </ReferenceInput>
          </Labeled>
          {
            <Labeled label="Тренажерный зал тренера">
              <ReferenceInput
                source="relations.trainerGym"
                reference="1:trainerGym"
                filter={{ users: [{ id: trainerId }]}}
              >
                <SelectInput
                  label="Выберите зал"
                  source="id"
                  optionText={"properties.customName.value"}
                />
              </ReferenceInput>
            </Labeled>
          }
        </>
      }
    </>
  )
}


// {entityName === "training" && (
//   <Labeled label="Место тренировки">
//     <ReferenceInput source="trainerGym" reference="1:trainerGym">
//       <SelectInput
//         label="Выберите тренажерные залы тренера"
//         source="properties"
//         optionText="properties.customName.value"
//       />
//     </ReferenceInput>
//   </Labeled>
// )}